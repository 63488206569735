.training-main {
  padding: 80px 50px 50px;
  max-width: 1440px;
  margin-inline: auto;
  margin-bottom: 50px;
}

.black-tint-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to top, black, rgba(0, 0, 0, 0.5) 99%); */
  z-index: 200;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1666666716337204px;
  font-family: "barlow", sans-serif;
}

.prod-page-heading1 {
  font-family: barlow;
  font-size: 56px;
  font-weight: 700;
  line-height: 80.92px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.prod-page-heading2 {
  font-family: barlow;
  font-size: 28px;
  font-weight: 300;
  line-height: 40.46px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.training-bg-1 {
  display: flex;
  position: absolute;
  z-index: 0;
  content: "";
  height: 730px;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 20%;
  z-index: 0;
  scale: 1.5;
  background: linear-gradient(90deg,
      rgba(124, 15, 175, 1) 43%,
      rgba(152, 22, 120, 1) 63%);
  filter: blur(140px);
  opacity: 60%;
}

.training-bg-2 {
  display: flex;
  position: absolute;
  z-index: 0;
  content: "";
  height: 730px;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 0;
  background: linear-gradient(90deg, rgba(46, 117, 255, 1) 0%, rgba(46, 117, 255, 0.5075280112044818) 100%);
  filter: blur(140px);
  scale: 1.5;
  opacity: 40%;

}

.training-main-card-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  height: max-content;
  position: relative;
  z-index: 1;
}

.training-main-text-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 30px;
  width: 50%;
}

.training-main-heading {
  font-family: barlow;
  font-size: 34.04px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.training-main-content {
  font-family: barlow;
  font-size: 24px;
  font-weight: 300;
  line-height: 40.46px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.training-main-video {
  position: relative;
  border-radius: 30px;
  height: 0;
  width: 100%;
  padding-bottom: 36.25%;
  overflow: hidden;
}

.training-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.training-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 50px;
  column-gap: 50px;
  max-width: 1440px;
  position: relative;
  z-index: 1;
}

.training-card-heading {
  position: relative;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: barlow;
  font-size: 56px;
  font-weight: 700;
  line-height: 49.18px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.training-card-link {
  border: 1px solid rgba(255, 255, 255, 0.15);
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.1);
}

.training-img-container {
  position: relative;
  overflow: hidden;
  border-radius: 22px 22px 0px 0px;
}

.training-card-img {
  width: 100%;
  border-radius: 22px 22px 0px 0px;
}

.training-card-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.training-img-hovered {
  scale: 1.1;
  transition: 2s;
}

.training-card-name {
  margin-top: 15px;
  margin-left: 20px;
  font-family: barlow;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.training-card-duration {
  font-family: barlow;
  margin: 10px 20px 20px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

.training-stats {
  margin-top: 100px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: white;
}

.training-stat-left {
  display: flex;
  gap: 20px;
  width: 100%;
}

.training-stats-certification {
  width: 100%;
}

.training-stats-hired {
  width: 100%;
}

.training-stats-heading1 {
  display: block;
  font-family: barlow;
  font-size: 55px;
  font-weight: 500;
  line-height: 112.5px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.training-stats-heading2 {
  display: block;
  text-align: center;
  font-family: barlow;
  font-size: 30px;
  font-weight: 700;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.5);
}

.training-stats-review {
  width: 35%;
  height: 417.12px;
}

.training-stats-review .swiper {
  width: 100%;
  height: 100%;
}

.training-stats-review .swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.training-stats-review .swiper-button-prev,
.training-stats-review .swiper-button-next {
  color: white;
}

.training-stats-review .swiper-button-prev:hover,
.training-stats-review .swiper-button-next:hover {
  color: rgba(219, 255, 0, 1);
}

.trainee-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-inline: 15%;
}

.trainee-profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.trainee-profile-review {
  font-family: barlow;
  font-size: 20.84px;
  font-style: italic;
  font-weight: 300;
  line-height: 31.9px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.trainee-profile-name {
  font-family: barlow;
  font-size: 16px;
  font-weight: 700;
  line-height: 24.48px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.trainee-profile-location {
  font-family: barlow;
  font-size: 16px;
  font-weight: 300;
  line-height: 24.48px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.training-certificate-card-container {
  margin-top: 110px;
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 531px;
  position: relative;
  z-index: 1;
}

.training-certificate {
  width: 100%;
  height: 100%;
}

.training-certificate img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.training-certificate-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 30px;
  width: 50%;
}

@media screen and (max-width: 600px) {
  .training-card-container {
    grid-template-columns: 1fr !important;
  }

  .training-stat-left {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 770px) {
  .training-bg-1 {
    right: 50%;
  }

  .training-bg-2 {
    height: 1024px;
    top: -20%;
  }

  .training-main {
    padding: 60px 20px 20px;
  }

  .training-card-container {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
  }

  .training-main-card-container {
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }

  .training-main-text-card {
    gap: 20px;
    border-radius: 30px;
    width: 100%;
  }

  .training-main-video {
    padding-bottom: 56.25%;
    border-radius: 20px;
  }


  .training-main-heading {
    font-size: 28px;
    line-height: 36px;
  }

  .training-main-content {
    font-size: 20px;
    line-height: normal;
  }

  .training-card-heading {
    font-size: 26px;
    line-height: normal;
  }

  .training-stats {
    flex-direction: column;
    gap: 20px;
  }

  .training-certificate-card-container {
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }

  .training-stats-certification {
    width: 100%;
    text-align: center;
  }

  .training-stats-hired {
    width: 100%;
    text-align: center;
  }

  .training-stats-review {
    width: 100%;
    height: 450px;
  }

  .training-certificate-card {
    width: 100%;
    border-radius: 20px !important;
  }

  .training-certificate img {
    border-radius: 20px !important;
  }
}

@media (min-width: 770px) and (max-width: 1024px) {
  .training-main-card-container {
    flex-direction: column-reverse;
    gap: 20px;
    height: 100%;
  }

  .training-main-text-card {
    width: 100%;
  }

  .training-main-video {
    padding-bottom: 56.25%;
  }

  .training-card-container {
    grid-template-columns: 1fr 1fr !important;
  }

  .training-stats {
    flex-direction: column;
    gap: 20px;
  }

  .training-stats-certification {
    text-align: center;
  }

  .training-stats-hired {
    text-align: center;
  }

  .training-stats-review {
    width: 100%;
  }

  .training-certificate-card-container {
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }

  .training-certificate-card {
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .training-main-text-card {
    width: 40%;
  }

  .training-main-video {
    padding-bottom: 39%;
  }

  .training-main-heading {
    font-size: 30px;
    line-height: 37px;
  }

  .training-main-content {
    font-size: 22px;
    line-height: initial;
  }

  .training-card-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1440px) {

  /* .training-main {
    padding: 0px;
  } */
  .training-card-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}