.Leaders_Overview {
  padding: 0px 20px;
  position: relative;
  z-index: 1;
}
.Leaders_Overview .custom-card {
  display: flex;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-between;
  transition: background 0.8s ease-in-out;
  padding-right: 10px;
}
.leader-cards-container{
  padding: 0 100px;
}
.about-bg1 {
  position: absolute;
  content: "";
  right: 0;
  top: 30%;
  width: 1030px;
  height: 330px;
  border-radius: 50%;
  background: linear-gradient(120deg, #8e69b2, #0f0cd6);
  filter: blur(500px);
  z-index: -1;
  scale: 1.2;
}

.Leaders_Overview .custom-card:hover {
  background: linear-gradient(120deg, #8e69b26e, #0f0cd669);
  transition: background 0.8s ease-in-out;
}
.custom-card-img {
  display: flex;
  max-width: 250px;
  max-height: 255px;
  width: auto;
  height: auto;
}

.custom-card-body {
  width: 350px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.descript_section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 15px;
}
.custom-card-title {
  font-family: barlow;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
  text-wrap: nowrap;
  text-align: left;
}
.desg {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
.custom-card-text {
  font-family: barlow;
  font-size: 18px;
  line-height: normal;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.772);
  text-wrap: wrap;
  text-align: left;
}
.custom-card-text1 {
  font-family: barlow;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: rgba(255, 255, 255, 0.772);
  text-wrap: wrap;
  text-align: left;
}
.icon_section {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 60px;
}
.li_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.lin_icon {
  color: aliceblue;
  height: 30px;
  width: 30px;
}
.lin_icon:hover {
  color: rgba(219, 255, 0, 1);
}
@media (min-width: 200px) and (max-width: 455px) {
  .Leaders_Overview .custom-card {
    border-radius: 15px;
  }
  .custom-card-body {
    width: 200px;
    gap: 0px;
  }
  .custom-card-img {
    margin-top: 5%;
    max-width: 100px;
    max-height: 105px;
  }
  .custom-card-title {
    font-size: 17px;
  }
  .custom-card-text {
    font-size: 12px;
    text-wrap: wrap;
  }
  .icon_section {
    margin-top: 0px;
  }
  .custom-card-text1 {
    font-size: 12px;
    text-wrap: wrap;
  }
  .lin_icon {
    height: 20px;
    width: 20px;
  }
  .leader-cards-container{
    padding: 0;
  }
}

@media (min-width: 455px) and (max-width: 670px) {
  .Leaders_Overview .custom-card {
    border-radius: 20px;
    justify-content: space-around;
  }
  .descript_section {
    gap: 15px;
  }
  .custom-card-body {
    width: 220px;
    gap: 0px;
  }
  .icon_section {
    margin-top: 0px;
  }
  .custom-card-img {
    margin-top: 6%;
    max-width: 150px;
    max-height: 155px;
  }
  .custom-card-title {
    font-size: 22px;
  }
  .custom-card-text {
    font-size: 17px;
    text-wrap: wrap;
  }
  .custom-card-text1 {
    font-size: 17px;
    text-wrap: wrap;
  }
  .lin_icon {
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 900px) and (max-width: 1127px) {
  .Leaders_Overview .custom-card {
    border-radius: 24px;
    justify-content: space-around;
  }
  .custom-card-body {
    width: 500px;
    gap: 0px;
  }
  .custom-card-img {
    margin-top: 4%;
    max-width: 190px;
    max-height: 195px;
  }
  .custom-card-title {
    font-size: 25px;
  }
  .custom-card-text {
    font-size: 20px;
    text-wrap: wrap;
  }
  .icon_section {
    margin-top: 60px;
  }
  .custom-card-text1 {
    font-size: 20px;
    text-wrap: wrap;
  }
  .lin_icon {
    height: 30px;
    width: 30px;
  }
}

@media (min-width: 1128px) and (max-width: 1365px) {
  .Leaders_Overview .custom-card {
    border-radius: 24px;
    justify-content: space-around;
  }
  .custom-card-img {
    margin-top: 4%;
    max-width: 190px;
    max-height: 195px;
  }
  .custom-card-title {
    font-size: 35px;
  }
  .custom-card-text {
    font-size: 28px;
    text-wrap: wrap;
  }
  .icon_section {
    margin-top: 0px;
  }
  .lin_icon {
    height: 35px;
    width: 35px;
  }
}
