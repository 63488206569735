.details-wrapper {
    padding: 0% 8% ;
    font-family: "barlow";
    color: rgba(255, 255, 255, 1);
}

.Card-name {
    padding-top: 5rem;
    font-size: 35px;
    font-weight: 200;
    font-family: "barlow";
    letter-spacing: 0.17px;

}

.Author {
    font-weight: 400;
    /* font-size: 15px; */
    margin: 0;

}
.Date{
    margin-top: 5px;
}
.readTime{
    margin-top: 5px;
}
.dateTime-container {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    color: rgba(255, 255, 255, 0.5);
    column-gap: 3px;
    /* font-size: 12px; */
}

.about {
    font-size: 15px;
    font-weight: 200;
    margin-top: -10px;
    /* line-height: 28px; */
     color: rgba(255, 255, 255, 0.7);

}
.Date-icon-container {
    display: flex;
    justify-content: space-between;
}
.icon-container{
    color:rgba(255, 255, 255, 0.5);
}
.icon-container :hover{
 color:rgba(255, 255, 255, 1);
}
/* .blog-img{
    height:.5 rem;
    width: 10 rem;
} */
.font-Img{
      height: 420px ;
      width: 100%;
      object-fit: cover;
}
.content-heading {
    /* margin-top: 30px; */
    margin-bottom: 10px;

}

.blog-content {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.7);
    text-align: left !important;
}
/* .blog-content2{
    margin-top:20px;
} */
.sub-heading {
    color: rgba(255, 255, 255, 1);
    padding:0%;
    font-weight: 200;
}

.subtitle {
    color: rgba(255, 255, 255, 1);
    letter-spacing: 1px;
    padding-top: 10px;
    margin-bottom: 20px;
    }
.blog-Conclusion {
    font-size: 16px;
    font-weight: 100;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.5);
}
.blog-button{
    outline: none;
    border-radius: 12px;
    border-width: 2px;
    background-color: rgba(255, 255, 255, 0.05);
    font-family: "barlow", sans-serif;
    padding: 0px 14.09px;
    height: 37.574px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: auto !important;
}
.BlogButton{
    margin: -2vh 0vw 0vh;
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
    flex-wrap: wrap;
    
    /* justify-content: flex-start; */
}
@media screen and (min-width: 1440px){

    .details-wrapper {
        max-width: 1440px;
        margin-inline: auto;
        /* padding: 0% 35%; */
    }

    .blog-content {
        text-align: left !important;
    }
}
@media screen and (min-width: 1440px){

    .details-wrapper {
        max-width: 1440px;
        margin-inline: auto;
        /* padding: 0% 35%; */
    }

    .blog-content {
        text-align: left !important;
    }
}
/* @media screen and (min-width:600px){
    .details-wrapper {
        padding: 0% 20em ;
        font-family: "barlow";
        color: rgba(255, 255, 255, 1);
    }
} */
@media screen and (min-width:1241px){
.details-wrapper {
    padding: 0% 20em ;
    font-family: "barlow";
    color: rgba(255, 255, 255, 1);
}}