.unhu-main-container {
    padding-inline: 100px;
    max-width: 1440px;
    margin-inline: auto;
    min-height: 100vh;
}
.unhu-title {
    font-family: "Barlow";
    font-size: 73.66px;
    font-weight: 700;
    line-height: 88.39px;
    text-align: center;
    color: #ffd600;
    padding-top: 15%;
}
.unhu-people-image {
    width: 100%;
    object-fit: cover;
}
.unhu-image-container {
    padding-top: 31px;
}
.unhu-hero img {
    width: 100%;
    height: 100%;
    position: relative;
}

.unhu-mockup-text-container {
    margin-top: 150px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.unhu-mockup img {
    width: 80%;
}

.unhu-heading {
    font-family: barlow;
    font-size: 44px;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 20px;
    text-align: left;
    color: #fff;
}

.unhu-para {
    font-family: barlow;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    width: 75%;
}

.unhu-para span {
    color: #fff;
}

.unhu-camel-post {
    width: fit-content;
    margin-top: 150px;
    margin-inline: auto;
    position: relative;
}

.unhu-camel-post img {
    width: 100%;
    position: relative;
}

.unhu-post {
    width: fit-content;
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    margin-inline: auto;
}

.unhu-banner {
    background: linear-gradient(
        45deg,
        #d66370 -0.09%,
        #d69063 49.91%,
        #d6c963 99.91%
    );
    font-family: barlow;
    font-size: 75.2px;
    font-weight: 600;
    line-height: 74px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 150px;
    padding: 50px 50px;
    position: relative;
}

.b1,
.b2,
.b3 {
    text-align: right;
}

.b4 {
    text-align: left;
}

.unhu-feature-text {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.unhu-feature-text .unhu-para {
    width: 30%;
}

.unhu-feature-text .unhu-text .unhu-para {
    width: 100%;
}

.unhu-features-texts-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 150px auto;
}
.unhu-feature-mockup {
    width: 100%;
    display: flex;
    justify-content: center;
}
.unhu-feature-mockup img {
    width: 80%;
}

.unhu-text-up {
    position: absolute;
    width: 30%;
    top: 0;
    right: 0;
}

.unhu-text-bottom {
    position: absolute;
    width: 30%;
    bottom: 0;
    left: 0;
}

.unhu-siren {
    text-align: center;
    margin-top: 50px;
}

.unhu-siren img {
    margin-bottom: 20px;
}

.unhu-heading2 {
    font-family: barlow;
    font-size: 26px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    margin: 150px 0;
}
.unhu-heading-title {
    display: none;
}
.unhu-people3 {
    display: none !important;
}
.unhu-text-dev {
    display: block;
}
.scroll-title-container {
    display: none;
}
.unhu-family {
    padding-top: 88px;
}
.dev {
    display: block;
}
.mobile {
    display: none;
}

@media (max-width: 600px) {
    .white-bar-container {
        margin-top: 32px;
        padding-inline: 24px;
    }
    .white-bar-icon {
        color: black;
        font-weight: 500;
    }
    .white-bar-title {
        font-family: Poppins;
        font-size: 10.58px;
        font-weight: 600;
        line-height: 15.87px;
        text-align: left;
        color: black;
        padding-left: 63px;
    }
    .white-bar {
        height: 52.9px;
        margin-inline: 10px;
        border-radius: 3.53px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        padding-inline: 10px;
        align-items: center;
        /* width: 100%; */
    }
    .wallet-title{
        padding-top: 28px;
    }
    /* .wallet-title{
      /* padding-inline: 15px !important; */
    .apolo-img {
        padding-top: 70px;
        padding-inline: 5px;
    }
    .reddem-title {
        padding-inline: 24px;
    }
    .police-image {
        padding-top: 40px;
    }
    .nearby-title {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        color: #ffffffb2;
        padding-inline: 24px;
        /* text-align: center; */
        padding-top: 30px;
    }
    .ambulance-image {
        padding-top: 50px;
    }
    .stay-title {
        font-family: Barlow;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        text-align: right;
        color: #ffffff;
        padding-top: 88px;
    }
    .span-color {
        color: #ffd600 !important;
    }
    .reddem-title {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: center;
        padding-top: 20px;
        color: #ffffffb2;
    }
    .unhu-people4 {
        padding-top: 88px;
    }
    .mobile {
        display: block;
    }
    .dev {
        display: none;
    }
    .scroll-title-container {
        display: block;
    }
    .unhu-text-dev {
        display: none;
    }
    .scroll-span-title2 {
        color: #ffd600;
    }
    .scroll-title {
        font-family: "Barlow";
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        text-align: right;
        color: white;
        padding-top: 87px;
    }
    .scroll-title2 {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: center;
        padding-inline: 46px;
        padding-top: 24px;
        color: #ffffff99;
    }
    .unhu-people3 {
        padding-top: 90px;
        display: block !important;
    }
    .unhu-heading-title {
        text-align: center;
        padding-inline: 74px;
        padding-top: 42px;
        font-size: 24px;
        line-height: 28.8px;
        display: block;
    }
    .unhu-main-container {
        padding-inline: 0px;
        margin-inline: auto;
        min-height: 100vh;
    }

    .unhu-hero img {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 7%;
    }
    .unhu-mockup-text-container {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .unhu-heading {
        display: none;
    }
    .unhu-mockup img {
        width: 100%;
    }
    .unhu-para {
        font-family: barlow;
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        width: 100%;
    }
    .unhu-post {
        width: fit-content;
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;
        margin-inline: 15px;
    }
    .unhu-camel-post img {
        width: 100%;
        position: relative;
        padding-inline: 20px;
    }
    .wallet-heading {
        padding-top: 88px;
        font-family: Barlow;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        text-align: right;
        color: #ffffff;
    }
    .unhu-banner {
        font-family: barlow;
        font-size: 40px;
        font-weight: 600;
        line-height: 45px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-top: 150px;
        padding: 10px;
        position: relative;
    }
    .unhu-feature-text {
        margin-top: 50px;
        display: flex;
        flex-direction: column-reverse;
    }
    .unhu-feature-text .unhu-para {
        width: 100%;
        text-align: center;
        padding-inline: 24px;
    }
    .unhu-feature img {
        padding-top: 32px;
        width: 100%;
        padding-inline: 10px;
    }
    .unhu-features-texts-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin: 30px;
    }
    .unhu-text-up {
        position: absolute;
        width: 100%;
        top: 0px;
        right: 0;
    }
    .unhu-feature-mockup img {
        width: 100%;
        padding-top: 25%;
    }
    .unhu-text-bottom {
        position: absolute;
        width: 100%;
        bottom: -30%;
        left: 0;
    }
    .sos-title {
        margin-top: 40%;
    }
    .unhu-police img {
        width: 100%;
        padding-inline: 20px;
    }
    .unhu-medical img {
        width: 100%;
        padding-inline: 20px;
    }
    .unhu-heading2 {
        font-family: barlow;
        font-size: 24px;
        font-weight: 400;
        padding-inline: 37px;
        line-height: 28.8px;
        text-align: center;
        color: #999999;
        margin: 50px 0;
    }
    .app-name{
        font-weight: 700;
    }
    .all-in-one {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: center;
        color: #fdd600;
        padding-bottom: 29px;
    }
}
