.chasi-main {
    padding-inline: 100px;
    max-width: 1440px;
    margin-inline: auto;
}
.chasi-herosection {
    padding-top: 80px;
}
.chasi-herosection img {
    width: 100%;
    height: 100%;
    position: relative;
}
.chasi-herosection-heading-container {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
}

.chasi-heading1 {
    font-family: barlow;
    font-size: 50px;
    font-weight: 700;
    line-height: 102.85px;
    text-align: center;
    color: #fff;
}

.chasi-heading1 span {
    font-family: barlow;
    font-size: 50px;
    font-weight: 700;
    line-height: 102.85px;
    text-align: center;
    color: rgba(139, 59, 0, 0.83);
}

.chasi-heading2 {
    margin-top: 56px;
    font-family: barlow;
    font-size: 50px;
    font-weight: 700;
    line-height: 87.06px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.chasi-heading2 span {
    font-family: barlow;
    font-size: 50px;
    font-weight: 700;
    line-height: 87.06px;
    text-align: center;
    color: rgba(186, 209, 101, 1);
}

.chasi-landing-mockup img {
    height: 100%;
    width: 100%;
}

.chasi-mockup-text {
    display: flex;
    justify-content: center;
    margin-top: 250px;
    height: 100%;
}

.chasi-mockup img {
    height: 70%;
}

.chasi-text-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.chasi-texts-heading {
    font-family: barlow;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.57px;
    text-align: left;
    background: linear-gradient(91.54deg, #dc8739 12.54%, #de9059 99.3%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-bottom: 10px;
}

.chasi-texts-para {
    font-family: barlow;
    font-size: 27px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
}

.chasi-texts-para span {
    color: rgba(186, 209, 101, 1);
}

.chasi-banner img {
    height: 100%;
    width: 100%;
}

.chasi-farmer-comment {
    position: relative;
}
.chasi-farmer-comment img {
    height: 100%;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}

.chasi-farmer-comment-text {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 0;
    width: 40%;
}

.chasi-farmer-payment {
    top: 60%;
    width: 20%;
}

.chasi-profile-container {
    display: flex;
    justify-content: center;
    margin-top: 250px;
    height: 100%;
}

.chasi-profile-mockup img {
    height: 70%;
}

.chasi-icon-text-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.chasi-icon-text {
    display: flex;
    align-items: center;
}

.chasi-icon img {
    width: 70%;
}

.chasi-text {
    font-family: barlow;
    font-size: 24px;
    font-weight: 600;
    line-height: 32.68px;
    text-align: left;
    background: linear-gradient(90deg, #ee804d 0%, #de9059 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.dev {
    display: block;
}
.mobile {
    display: none;
}
.chasi-bg {
    background-color: "#081c15";
}

@media (max-width: 600px) {
    .chasi-farmer-comment-text {
        position: absolute;
        top: 20%;
        left: 10%;
        right: 0;
        width: 40%;
    }
    .chasi-first-title {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;
        color: #c9ff00;
    }
    .chat-container {
        padding-inline: 24px;
        padding-top: 36px;
    }
    .profile-right-image {
        padding-top: 162px;
        padding-inline: 24px;
    }
    .health-schems {
        padding-top: 35px;
        padding-inline: 24px;
    }
    .wallet-image {
        padding-inline: 24px;
        padding-top: 47px;
    }
    .Tracking-title {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;
        color: #c9ff00;
        padding-top: 35px;
    }
    .chasi-farmer-payment {
        top: 35%;
        width: 20%;
    }
    .scheme-title {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;
        color: #c9ff00;
    }
    .familydesc-img {
        padding-top: 35px;
        padding-inline: 24px;
    }
    .chasi-banner img {
        height: 100%;
        width: 100%;
        padding-inline: 20px;
        padding-top: 100px;
    }
    .farmer1 {
        padding-top: 163px;
        padding-inline: 24px;
        position: relative;
    }
    .farmer1-title {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        text-align: center;
        color: white;
        width: 100%;
        padding-inline: 40px;
    }
    .farmer1-container {
        position: absolute;
        bottom: 3%;
        left: 0;
        right: 0;
    }
    .rental-img {
        padding-inline: 24px;
        padding-top: 52px;
    }
    .farmer1-span {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        text-align: center;
        color: #dbff00;
    }
    .homescreen {
        padding-top: 52px;
    }
    .marketprice {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
        color: #999999;
        padding-inline: 24px;
    }
    .govt-subtitle {
        font-family: Inter;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
        color: #999999;
        padding-top: 17px;
    }
    .sub-subtitle {
        font-family: Inter;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
        color: white;
    }
    .govt-title {
        text-align: center;
        padding-top: 162px;
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        color: #c9ff00;
    }
    .weather-title {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;
        color: #c9ff00;
        padding-top: 163px;
    }
    .weather-title1 {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;
        color: #c9ff00;
        padding-top: 35px;
    }
    .weather-conatiner {
        padding-top: 95px;
    }
    .health-title {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;
        color: #c9ff00;
    }
    .weather1-title {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 400;
        line-height: 38.4px;
        text-align: center;

        color: white;
    }
    .weather2-title {
        font-family: Barlow;
        font-size: 32px;
        font-weight: 400;
        line-height: 38.4px;
        text-align: center;

        color: white;
    }
    .helper-title {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: center;
        color: white;
        padding-top: 73px;
    }
    .span-color-chasi {
        color: #c9ff00 !important;
    }
    .heaidng1 {
        padding-top: 10%;
        font-family: Barlow;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: center;
        color: white;
    }
    .heaidng2 {
        font-family: Barlow;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: center;
        color: white;
    }
    .hero-title {
        padding-top: 10%;
    }
    .dev {
        display: none;
    }
    .mobile {
        display: block;
    }
    .chasi-main {
        max-width: 1024px;
        padding-inline: 0px;
    }
    .chasi-herosection {
        padding-top: 50px;
    }
    .chasi-herosection-heading-container {
        position: absolute;
        top: 65%;
        left: 0;
        right: 0;
    }
    .chasi-bg1 {
        padding-top: 69px;
        position: relative;
    }
    .chasi-bg1-heading {
        font-family: "barlow";
        font-size: 30px;
        font-weight: 700;
        line-height: 10px;
        text-align: center;
        color: #fff;
    }
    .chasi-bg1-heading span {
        font-family: "barlow";
        font-size: 30px;
        font-weight: 700;
        line-height: 10px;
        text-align: center;
        color: rgba(139, 59, 0, 0.83);
    }
    .chasi-bg1-text {
        position: absolute;
        top: 30%;
        left: 0;
        right: 0;
    }

    .chasi-heading1 {
        font-family: "barlow";
        font-size: 30px;
        font-weight: 700;
        line-height: 10px;
        text-align: center;
        color: #fff;
    }
    .chasi-heading1 span {
        font-family: "barlow";
        font-size: 30px;
        font-weight: 700;
        line-height: 10px;
        text-align: center;
        color: rgba(139, 59, 0, 0.83);
    }

    .chasi-heading2 {
        /* margin-top: 56px; */
        font-family: "barlow";
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        padding-inline: 10px;
    }
    .chasi-heading2 span {
        font-family: "barlow";
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        color: rgba(186, 209, 101, 1);
    }
    .chasi-mockup-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin-top: 250px; */
        height: 100%;
        gap: 50px;
    }
    .chasi-mockup img {
        /* height: 70%; */
        width: 100%;
        padding-inline: 24px;
    }
    .chasi-farmer-comment img {
        height: 100%;
        width: 100%;
        position: relative;
        margin-top: 56px;
        padding-bottom: 10px;
    }
    .chasi-farmer-comment1 img {
        height: 100%;
        width: 100%;
        position: relative;
        margin-top: 203px;
        padding-bottom: 10px;
    }
    .chasi-profile-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin-top: 250px; */
        height: 100%;
    }
    .chasi-icon-text-container {
        /* padding-top: 5%; */
        padding-inline: 10px;
    }
}
