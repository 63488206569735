.product-container {
  position: relative;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  scroll-behavior: smooth;
}

.prodSwiper {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prod-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
  z-index: 10;
}

.blur {
  opacity: 50%;
}

.iphone,
.ipad,
.laptop {
  width: 100%;
  height: 550px;
  object-fit: cover;
  border-radius: 30px;
  position: relative;
}

.prod-content {
  display: none;
  text-align: center;
  margin-top: 35px;
}

.prod-heading {
  font-family: barlow;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.prod-sector {
  font-family: barlow;
  font-size: 24px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  opacity: 50%;
  margin-bottom: 10px;
}

.view-button {
  border-radius: 20px;
  background-color: rgba(225, 225, 225, 0.1);
  backdrop-filter: blur(10px);
  color: #fff;
  display: inline;
  padding: 10px 16px;
  cursor: pointer;
}

.view-button:hover {
  color: rgba(219, 255, 0, 1);
}

.tempbanner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  color: #fff;
  margin-top: 35px;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(73.84552001953125px);
  backdrop-filter: blur(73.84552001953125px);
  height: 51px;
  border: 1px solid rgba(126, 126, 126, 0.3);
}

.tempbannerContainer {
  display: flex;
  gap: 20px;
}
.tempheading {
  font-family: barlow;
  font-size: 21px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
}
.tempdesc {
  font-family: barlow;
  font-size: 16px;
  font-weight: 200;
  line-height: 26.4px;
  text-align: left;
  opacity: 75%;
}
.tempbtn {
  font-family: barlow;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.6px;
  text-align: left;
  text-align: center;
  padding: 13px 20px;
  color: rgba(219, 255, 0, 1);
  border-left: 1px solid rgba(126, 126, 126, 0.3);
  width: 135px;
}
.tempbtn:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
}

.prod-large-container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  height: 90%;
  justify-content: center;
  gap: 24px;
}

.prod-large-img {
  display: flex;
  flex-direction: column;
  /* width: 60%; */
  height: 100%;
}

.prod-large-img div {
  margin-inline: auto;
  padding-bottom: 10vh;
  height: 100vh;
}

.prod-large-img div:nth-child(1) {
  padding-top: 0;
}

.prod-large-img div:nth-child(4) {
  height: 100%;
  padding-bottom: 0;
}

.prod-large-desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: sticky;
  top: 20vh;
  width: 32%;
  height: 550px;
  padding: 25px 30px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.1);
}

.prod-large-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 26px;
}

.prod-large-heading {
  font-family: barlow;
  font-size: 34px;
  color: #fff;
}
.prod-large-info {
  font-family: barlow;
  font-size: 24px;
  margin-top: 25px;
  text-align: left;
}

.prod-large-info div {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
}

.prod-large-info span {
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
}

.prod-large-uses {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
  gap: 50px;
}
.prod-large-uses-content {
  display: flex;
  gap: 10px;
}
.prod-large-uses-img {
  margin-top: 1px;
}

.prod-large-uses-info {
  display: flex;
  flex-direction: column;
}

.prod-large-uses-title {
  font-family: barlow;
  font-size: 20px;
  font-weight: 200;
  color: rgba(255, 255, 255, 1);
}

.prod-large-uses-value {
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 200;
}

.prod-large-uses-btn {
  margin-top: 40px;
  width: auto;
  padding: 10px 16px;
  font-family: "barlow", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(73.84552001953125px);
  backdrop-filter: blur(73.84552001953125px);
  text-decoration: none;
  border-radius: 30px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

.prod-large-uses-btn:hover {
  background: rgba(255, 255, 255, 0.8);
  color: black;
  transition: 600ms;
}

@media only screen and (max-width: 767px) {
  .laptop {
    width: 100%;
    height: 100%;
  }
  .ipad {
    width: 100%;
    height: 100%;
  }
  .iphone {
    width: 100%;
    height: 100%;
  }
  .prodSwiper {
    margin-top: 20px;
    padding: 0;
    height: 280px;
  }
  .prod-content {
    position: relative;
    display: block;
  }
  .prod-heading {
    font-size: 18px;
    margin-bottom: 0;
  }
  .product-container {
    margin-top: 0;
  }
  .prod-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .left-arrow {
    display: none;
  }

  .right-arrow {
    display: none;
  }
  .tempbanner {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .laptop {
    width: 100%;
  }

  .ipad {
    width: 100%;
  }
  .tempbanner {
    height: auto;
  }
  .tempheading {
    font-size: 16px;
  }
  .tempdesc {
    font-size: 14px;
  }
  .prod-large-heading {
    font-size: 28px;
    line-height: normal;
  }
  .prod-large-desc {
    top: 20vh;
  }
  .prod-large-info {
    font-size: 20px;
    line-height: normal;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .laptop {
    width: 100%;
  }
  .ipad {
    width: 100%;
  }
  .tempbanner {
    height: max-content;
  }
  .tempheading {
    font-size: 16px;
  }
  .tempdesc {
    font-size: 14px;
  }
  .prod-large-desc {
    top: 24vh;
  }
}

@media only screen and (min-width: 1441px) {
  .left-arrow {
    left: 10%;
  }

  .right-arrow {
    right: 10%;
  }
}
