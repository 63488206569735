.medbot-container {
  padding-top: 169px;
  /* padding-left: 122px;
    padding-right: 122px; */
  max-width: 1440px;
  margin-inline: auto;
}
.medbot-title {
  font-family: "Barlow";
  font-size: 81.03px;
  font-weight: 700;
  line-height: 101.29px;
  text-align: left;
  color: #01c397;
}
.medbot-subtitle {
  font-family: "Barlow";
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  /* text-align: left; */
  padding-left: 300px;
  color: #b6b6b6;
}
.medbot-paragraph {
  font-family: "Barlow";
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  color: white;
}
.first-section {
  display: flex;
  justify-content: space-between;
}
.ai-title {
  font-family: "Barlow";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  color: #b1b1b1b1;
  margin-left: 282px;
  width: 589px;
}
.info-title {
  font-family: "Barlow";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  color: #b1b1b1b1;
  /* margin-left: 282px; */
  width: 703px;
}
.tts-title {
  font-family: "Barlow";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  text-align: center;
  color: #49f9e4;
  padding-top: 244px;
  padding-bottom: 63px;
}
.tts-subtitle {
  font-family: "Barlow";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  color: #b1b1b1b1;
  padding-left: 200px;
  /* width: 609px; */
}
.mul-title {
  font-family: "Barlow";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  text-align: left;
  color: white;
}
.mul-span {
  color: #01c397;
}
.mul-subtitle {
  font-family: "Barlow";
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
  color: #b1b1b1b1;
  width: 650px;
  padding-top: 60px;
}
.secure-title {
  font-family: "Barlow";
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  text-align: left;
  color: #b1b1b1b1;
  width: 500px;
}
.dev {
  display: block;
}
.mobile {
  display: none;
}

@media (max-width: 600px) {
  .medbot-title {
    font-family: "Barlow";
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
    color: #01c397;
  }
    .nurse-image {
        padding-top: 120px;
        /* padding-bottom: 120px; */
        /* padding-inline: 24px; */
    }
    .ai-image{
        padding-top: 15%;
        padding-inline: 24px;
    }
  .export-title {
    font-family: Barlow;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
    color: #01c397;
  }
  .stt-subtitle {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #999999;
    padding-inline: 24px;
    padding-top: 24px;
  }
  .stt-title {
    padding-top: 120px;
    font-family: Barlow;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
    color: #01c397;
  }
  .tts-bg {
    padding-top: 127px;
    padding-inline: 24px;
  }
  .search-container {
    padding-top: 24px;
  }
  .ai-title-mobile {
    padding-inline: 72px;
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #999999;
    padding-top: 120px;
  }
  .dev {
    display: none;
  }
  .mobile {
    display: block;
  }
  .medbot-container {
    padding-top: 80px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .medbot-subtitle {
    font-family: "Barlow";
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    /* text-align: left; */
    padding-left: 0px;
    color: #999999;
    padding-bottom: 44px;
  }
  .first-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .first-left {
    display: flex;
    justify-content: center;
  }

  .first-img {
    width: 60%;
    height: 60%;
  }
  .ai-title {
    font-family: "Barlow";
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    padding-top: 10%;
    text-align: center;
    color: #999999;
    margin-left: 0px;
    width: 100%;
    padding-inline: 24px;
    /* text-align: justify; */
  }
  .second-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .second-img {
    width: 60%;
    height: 60%;
    padding-left: 2%;
  }
  .second-right {
    display: flex;
    justify-content: center;
  }
  .info-title {
    font-family: "Barlow";
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: right;
    color: #b1b1b1b1;
    /* margin-left: 282px; */
    width: 703px;
  }
  .tts-title {
    font-family: "Barlow";
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #49f9e4;
    padding-top: 244px;
    padding-bottom: 20px;
  }
  .third-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .third-left {
    display: flex;
    justify-content: center;
  }
  .tts-img {
    width: 60%;
    height: 60%;
    padding-bottom: 30px;
  }
  .tts-subtitle {
    font-family: "Barlow";
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: #b1b1b1b1;
    padding-left: 0px;
    /* width: 609px; */
  }
  .multi-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mul-title {
    font-family: "Barlow";
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: white;
  }

  .mul-subtitle {
    font-family: "Barlow";
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    text-align: right;
    color: #b1b1b1b1;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20%;
  }
  .multi-right {
    display: flex;
    justify-content: center;
  }
  .chat-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .chat-img {
    padding-top: 10%;
  }
  .chat-left {
    display: flex;
    justify-content: center;
  }
  .last-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .secure-title {
    font-family: "Barlow";
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    text-align: right;
    color: #b1b1b1b1;
    width: 100%;
    padding-bottom: 20%;
  }
  .last-right {
    display: flex;
    justify-content: center;
  }
}
