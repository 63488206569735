body {
  margin: 0;
  padding: 0px;
  color: #ffffff80;
}

.data_container {
  position: relative;
  z-index: 2;
  padding-bottom: 100px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 0px 20px;
  gap: 30px;
}

.tab_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-bg1 {
  display: flex;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin-inline: auto;
  top: 30%;
  width: 50vw;
  height: 330px;
  border-radius: 50%;
  background: linear-gradient(149deg,
      rgba(124, 15, 175, 1) 50%,
      rgba(8, 145, 221, 1) 100%);
  filter: blur(140px);
  z-index: -1;
  scale: 1.5;
  opacity: 75%;
}

.desktop_Container {
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  /* min-width: 780px; */
}

.contact-details {
  display: flex;
  flex-direction: column;
  font-family: "barlow", sans-serif;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-details {
  font-family: "barlow", sans-serif;
  text-align: center;
}

.Header {
  font-size: 1.5rem;
  /* Changed to relative unit */
  font-weight: 400;
  line-height: 1.875rem;
  /* Changed to relative unit */
  letter-spacing: 0em;
  text-align: center;
  font-family: "barlow", sans-serif;
  color: white;
}

.All-btn {
  background-color: #ffffff1f;
  padding: 7px 7px;
  display: flex;
  justify-content: center;
  border-radius: 8.04px;
  color: rgba(255, 255, 255, 1);
  height: 44px;
}

.All-btn div {
  background: transparent;
  color: #ffffff;
  width: 100%;
  font-weight: 300;
  font-size: 1rem;
  /* Changed to relative unit */
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 2px;
}

.All-btn div:last-child {
  border-right: none;
}

.All-btn-active {
  background: #dbff00 !important;
  color: black !important;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-radius: 5px;
  font-weight: 600 !important;
}

.icon {
  height: 33.53px;
  width: 33.53px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call_details {
  display: flex;
  align-items: center;
  color: #ffffff;
  gap: 20px;
}

.map-details {
  display: flex;
  align-items: center;
  color: #ffffff;
  gap: 20px;
}

.contact-icon {
  color: rgba(255, 255, 255, 0.75);
}

#dtlname {
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 15px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 10px;
  background: transparent;
  outline: none;
  border: none;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.1);
}

#dtlname::placeholder {
  color: rgba(255, 255, 255, 0.75);
  opacity: 1;
}

#dtlname:focus {
  border: 1px solid rgba(255, 255, 255, 0.75);
}

.submit button {
  width: 100%;
  color: #000000 !important;
  background: transparent;
  outline: none;
  border: none;
  background-color: #FFF;
  height: 44px;
  border-radius: 50px;
  font-weight: 600;
}

.submit button:hover {
  background-color: rgb(228, 228, 228);
  color: white;
}

@media screen and (max-width: 300px) {
  .All-btn div {
    font-size: 5vw;
  }

  .data_container {
    padding-inline: 5px !important;
  }

  .call_details p,
  .map-details p,
  #dtlname,
  .submit button {
    font-size: 6vw;
  }
}

@media only screen and (max-width: 770px) {
  .tab_container {
    display: flex;
    padding-top: 5rem;
  }

  .desktop_Container {
    padding: 0px;
  }

  .Header {
    padding-top: 59px;
    padding-bottom: 25px;
    font-size: 2rem;
    /* Changed to relative unit */
    font-weight: 700;
    line-height: 1.875rem;
    /* Changed to relative unit */
    text-align: center;
    color: white;
  }

  .All-btn {
    background-color: #ffffff1f;
    padding: 7px 7px;
    display: flex;
    justify-content: center;
    gap: 1px;
    border-radius: 8.04px;
    color: rgba(255, 255, 255, 1);
    height: 44px;
  }

  .icon {
    background: #ffffff1f;
    border-radius: 8.04px;
  }

  .call_details p,
  .map-details p {
    padding-left: 10px;
    color: #ffffff;
  }

  .map-details p {
    width: 80%;
  }

  .submit button {
    background-color: rgb(40, 62, 160);
  }

  .submit button:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .data_container {
    padding-inline: 20px;
  }

  .desktop_Container {
    min-width: 90vw;
  }
}

@media only screen and (min-width: 771px) {
  body {
    font-family: "barlow", sans-serif;
  }

  .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .data_container {
    margin: 0 auto !important;
  }

  .tab_container {
    display: flex;
    padding-top: 5rem;
  }

  .desktop_Container {
    min-width: 780px;
  }
}

@media screen and (min-width: 2000px) {
  .data_container {
    padding-top: 9vh;
  }

  .desktop_Container {
    min-width: 780px;
  }
}