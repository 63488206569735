body {
  background-color: black;
  margin: 0;
  padding: 0;
  font-family: "barlow", sans-serif;
}

.data:h2 {
  color: rgba(255, 255, 255, 1);
}

.prod-page-herosection {
  position: relative;
  z-index: 1;
  height: 46vh;
}

.prod-page-bg1 {
  display: flex;
  position: absolute;
  z-index: 0;
  content: "";
  width: 730px;
  height: 1020px;
  border-radius: 50%;
  top: 70%;
  left: 0;
  right: 0;
  margin: auto;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(136,18,151,1) 29%, rgba(152,22,120,1) 82%, rgba(0,0,0,1) 100%);
  filter: blur(140px);
  scale: 1.5;
  opacity: 60%;
}

.prod-page-bg-2{
  display: flex;
  position: absolute;
  z-index: 0;
  content: "";
  width: 730px;
  height: 1020px;
  border-radius: 50%;
  top: -90%;
  left: 0;
  right: 0;
  margin: auto;
  background: linear-gradient(180deg, rgba(20,51,128,1) 29%, rgba(0,0,0,1) 100%);
  filter: blur(140px);
  scale: 1.5;
  opacity: 50%;
}

.black-tint-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to top, black, rgba(0, 0, 0, 0.5) 99%); */
  z-index: 200;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1666666716337204px;
  font-family: "barlow", sans-serif;
}

.prod-page-heading1 {
  font-family: barlow;
  font-size: 56px;
  font-weight: 700;
  line-height: 80.92px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.prod-page-heading2 {
  font-family: barlow;
  font-size: 28px;
  font-weight: 300;
  line-height: 40.46px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.product-wrapper {
  max-width: 1440px;
  margin-inline: auto;
}

.main-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 50px;
}

.parent {
  gap: 100px;
  flex-wrap: calc(2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.product-skleton{
  max-width: 556px;
  max-height: 406px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(90deg, #2b2b2b 25%, #282828 50%, #202020 75%);
  background-size: 200% 100%;
  animation: skeleton-animation 1s infinite linear;
}

@keyframes skeleton-animation {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

.scaleimg {
  overflow: hidden;
  max-width: 556px;
  max-height: 406px;
  border-radius: 20px 20px 0 0;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.scaleimg > img {
  z-index: 2;
  position: relative;
  /* cursor: pointer; */
  height: 100%;
  width: 100%;
}
.hover_text button {
  width: 120px;
  height: 33px;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 2;
  background: rgba(45, 45, 45, 0.86);
  color: white;
  border: none;
  border-radius: 5px;
}

.hover_text button:hover {
  color: rgba(219, 255, 0, 1);
  background: rgba(45, 45, 45, 1);
  cursor: pointer;
  transition: 1s ease-in-out;
}

.scaleimg .hover_text {
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  width: 100%;
  height: 105%;
  right: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scaleimg:hover .hover_text {
  right: 0;
}
.hovered img{
  scale: 1.1;
  transition: 1s;
}

.text_container {
  font-family: "barlow", sans-serif;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  z-index: 1;
  position: relative;
}

.text-name{
  font-size: 24px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.text-chip{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.text-chip span{
  background: rgba(255, 255, 255, 0.1);;
  font-weight: 400;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.prod-page-contact {
  max-width: 1440px;
  margin: 110px auto 24px;
  height: 100%;
  position: relative;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
}

.prod-page-contact-left {
  width: 32%;
  height: 300px;
  border-radius: 20px;
}

.prod-page-contact-left img {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}

.prod-page-contact-right {
  background-color: rgba(94, 137, 108, 1);
  height: 300px;
  border-radius: 20px;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: Barlow;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.33016982674598694px;
  text-align: left;
  color: white;
}

.language-container {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
}

@media only screen and (max-width: 599px) {
  .prod-page-bg1{
    left: -40%;
    top: 0;
    width: 250px;
  }
  .prod-page-bg-2{
    top: -600%;
    height: 1804px;
  }
  .main-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* column-gap: 10%; */
    row-gap: 0px;
  }
  .Heading h1 {
    padding-top: 7rem;
  }
  .prod-page-heading1 {
    font-size: 26px;
    line-height: normal;
    margin-bottom: 10px;
  }
  .prod-page-heading2 {
    font-size: 20px;
    line-height: normal;
  }
  .parent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    margin: 10px;
  }

  .image_container {
    margin-bottom: 1rem;
    height: fit-content;
    width: fit-content;
  }

  .image_container2 {
    margin-bottom: 5rem;
    height: fit-content;
    width: fit-content;
    height: 100%;
    width: 100%;
  }
  .prod-page-contact {
    flex-direction: column;
    padding-inline: 20px;
  }
  .prod-page-contact-right {
    font-size: 26px;
    width: 100%;
  }
  .prod-page-contact-left {
    width: 100%;
  }
}

@media screen and (min-width: 599px) and (max-width: 767px) {
  .prod-page-heading1 {
    font-size: 5vw;
    margin-bottom: 10px;
  }
  .prod-page-heading2 {
    font-size: 3vw;
    font-weight: 200;
  }
  .prod-page-contact {
    padding-inline: 20px;
  }
  .prod-page-contact-right {
    font-size: 32px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .prod-page-heading1 {
    font-size: 40px;
    line-height: 50px;
  }
  .prod-page-heading2 {
    font-size: 26px;
  }
  .prod-page-contact {
    padding-inline: 50px;
  }
  .prod-page-contact-right {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .prod-page-heading1 {
    font-size: 45px;
    line-height: 55px;
  }
  .prod-page-heading2 {
    font-size: 24px;
    line-height: 34px;
  }
  .prod-page-contact {
    padding-inline: 50px;
  }
}
