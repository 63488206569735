.background-color-mediclub {
  /* background: linear-gradient(
        180deg,
        rgba(62, 173, 153, 0.3) 0%,
        rgba(26, 71, 63, 0.3) 100%
    ); */
}
.mediclub-container {
  max-width: 1440px;
  margin-inline: auto;
}
.herosection {
  position: relative;
  height: 100%;
  width: 100%;
}

.herosection > img {
  /* opacity: 70%; */
}

.mediclub-logo {
  position: absolute;
  top: 80px;
  right: 50px;
}

.left-side {
  position: absolute;
  bottom: "100px";
  /* left: "20px"; */
  width: "30%";
}
.mediclub-mockup {
  position: relative;
}
.mediclub-mockup-text-container {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mediclub-text {
  width: 50%;
}

.mediclub-heading1 {
  font-family: barlow;
  font-size: 64px;
  font-weight: 600;
  line-height: 92.48px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

.mediclub-heading1 span {
  color: #fff;
}

.mediclub-heading2 {
  font-family: barlow;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  width: 65%;
  color: rgba(255, 255, 255, 0.6);
  padding-left: 10%;
}

.mediclub-heading2 span {
  color: #fff;
}

.mediclub-full-img-text {
  position: relative;
  margin-top: 250px;
}

.mediclub-mockup-top {
  position: absolute;
  top: 15%;
  left: 50%;
}
.dev {
  display: block;
}
.mobile {
  display: none;
}

@media (max-width: 600px) {
  .phone-section {
    padding-top: 56px;
    padding-bottom: 30px;
  }
  .help-title {
    font-family: Barlow;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
    color: #ffffff99;
    padding-inline: 24px;
  }
  .wallet-title {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff99;
    padding-inline: 42px;
  }
  .contribute-title {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff99;
    padding-inline: 42px;
    padding-top: 119px;
  }
  .notify-title {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff99;
    padding-inline: 44px;
    padding-top: 45px;
  }
  .gd-container {
    padding-top: 54px;
  }
  .specific-cotor-title {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff99;
    padding-inline: 75px;
    padding-top: 111px;
  }
  .connect-title {
    text-align: center;
    padding-inline: 24px;
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff99;
  }
  .girl-container {
    padding-top: 62px;
  }
  .exclusive-title {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    padding-inline: 60px;
    padding-top: 54px;
    color: #ffffff99;
  }
  .text {
    padding-inline: 42px !important;
  }
  .background-color-mediclub {
    background: black;
  }
  .span-color-mediclub {
    color: #ff8a00 !important;
  }
  .mediclub-mobile-logo {
    position: absolute;
    top: 5%;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
  }
  .mobile {
    display: block;
  }
  .dev {
    display: none;
  }
  .mediclub-heroimg {
    width: 100%;
  }
  .logo-img {
    width: 200px;
  }
  .mediclub-logo {
    position: absolute;
    top: 15%;
    right: 10px;
    /* left 0px; */
  }
  .mediclub-mockup-text-container {
    margin-top: 41px;
    flex-direction: column-reverse;
    gap: 43px;
  }
  .mediclub-text {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding-top: 10%; */
  }

  .mediclub-heading1 {
    font-family: barlow;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
  }
  .mockup-two {
    padding-top: 5%;
  }
  .left-side {
    position: absolute;
    bottom: "200px";
    left: "20px";
    width: "30%";
  }
}
