.CertificatePage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.certificate-name {
  font-family: "Open Sans";
  font-size: 36px;
  font-weight: 700;
  line-height: 49.03px;
  text-align: center;
  color: #091057;
  position: absolute;
  top: 353px;
  left: 0;
  right: 0;
}
.certificate-details {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24.51px;
  letter-spacing: 0.01em;
  text-align: center;
  bottom: 246px;
  left: 0;
  right: 0;
  position: absolute;
  color: #091057;
  padding-inline: 182px;
}
.certificate-span {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  letter-spacing: 0.01em;
  text-align: center;
}
.issue-date {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24.51px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #091057;
  position: absolute;
  left: 336px;
  bottom: 133px;
}
.NotFound {
  font-family: "barlow", sans-serif;
  font-size: 25px;
}

.EachCertificate {
  width: 55vw;
  font-family: "barlow", sans-serif;
  background: rgba(122, 122, 122, 0.07);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

/* strong{
    color: #a7c957;
} */
.CertificateDesc {
  width: 50vw;
  color: #e5e5e5;
  font-weight: 200;
  padding-bottom: 50px;
}

.verifiedsign {
  width: 30px;
}

.CertificationStat {
  display: flex;
  justify-content: space-between;
  width: 50vw;
}

.Certified {
  display: flex;
  align-items: center;

}

.VerifiedBox {
  align-items: center;
  text-align: center;
  margin-left: 5px;
  font-weight: 600;
  font-size: larger;
  color: #669200;
}
.qr-code{
  position: absolute;
  top: 140px;
  right: 123px;
  height: 110px;
  width: 110px;
}
.certificate-top{
  padding: 10%;
  
}
/* .certificate-container{
  width: 1160px  ;
  height:837px;
  position: relative,
} */

@media (max-width: 600px) {
  .CertificatePage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .CertificateDesc {
    width: 80vw;
    color: #e5e5e5;
    font-weight: 200;
    padding-bottom: 50px;
  }
  .CertificationStat {
   flex-direction: column;
   width: 80vw;
   /* justify-content: space-between; */
  }
  .EachCertificate {
    width: 80vw;
    font-family: "barlow", sans-serif;
    background: rgba(122, 122, 122, 0.07);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter:none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
}