.profile-container {
  display: flex;
  align-items: center;
  position: relative;
}

.arrow {
  cursor: pointer;
  height: 120px;
  transform: translateX(0%) translateY(0%);
  transition: transform 0.1s;
  width: 77px;
}

.arrow-top,
.arrow-bottom {
  background-color: #666;
  height: 4px;
  left: -5px;
  top: 50%;
  position: absolute;
  width: 71px;
}

.arrow-top:after,
.arrow-bottom:after {
  background-color: #fff;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}

.arrow-top {
  transform: rotate(45deg);
  transform-origin: bottom right;
}

.arrow-top:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}

.arrow-bottom {
  transform: rotate(-45deg);
  transform-origin: top right;
}

.arrow-bottom:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}

.arrow:hover .arrow-top:after {
  left: 0;
  transition-delay: 0.15s;
}

.arrow:hover .arrow-bottom:after {
  right: 0;
  transition-delay: 0s;
}

.arrow:active {
  transform: translateX(0%) translateY(0%) scale(0.9);
}

.dark-left-container {
  position: absolute;
  top: 30px;
  height: 470px;
  width: 140px;
  left: -1px;
  z-index: 4;
  rotate: 180deg;
  display: flex;
  background: linear-gradient(270deg, black, rgba(0, 0, 0, 0));
  -webkit-box-pack: center;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
}

.dark-right-container {
  position: absolute;
  top: 30px;
  height: 470px;
  width: 140px;
  right: -1px;
  z-index: 4;
  display: flex;
  background: linear-gradient(270deg, black, rgba(0, 0, 0, 0));
  -webkit-box-pack: center;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
}

.myProfileSwiper {
  width: 100%;
  padding-top: 50px !important;
  /* padding-bottom: 50px !important; */
  margin: 0 auto;
}

.profileheading {
  text-align: center;
  font-family: "barlow";
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0px;
  margin-bottom: 15vh;
}
.profilepaging {
  display: none;
}
.profile-btn {
  display: none;
}
.main {
  background-position: center !important;
  background-size: cover !important;
  width: 352px !important;
  height: 70vh !important;
  margin-top: 20px;
  overflow: visible;
}

.personImg {
  height: 470px;
  width: 335px;
  z-index: 2;
  position: absolute;
  margin-left: 8px;
  border-radius: 10%;
}
.bg {
  margin-top: 45px;
  /* box-shadow: 0 4px 8px 0 rgb(217, 255, 0), 0 6px 20px 0 rgb(251, 255, 0); */
  height: 430px;
  width: 350px;
  border-radius: 32px;
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.15);
}
.bgBorder {
  border: 3px solid black;
  height: 422px;
  width: 341px;
  margin: 4px;
  border-radius: 10%;
}
.profile {
  background: linear-gradient(to bottom, transparent 30%, rgb(14, 14, 14) 100%);
  position: absolute;
  z-index: 4;
  height: 289px;
  width: 351px;
  top: 186px;
  left: 0;
  border-bottom: 3px solid black;
  border-radius: 8%;
}
.personName {
  position: absolute;
  bottom: 25px;
  left: 20px;
  width: 71%;
  font-family: "barlow", sans-serif;
  color: white;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
}

.personDesignation {
  font-family: "barlow", sans-serif;
  position: absolute;
  bottom: 13px;
  left: 20px;
  line-height: 22px;
  width: 70%;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
}

.description-section {
  display: none;
  position: absolute;
  top: 540px;
  /* height: 100%; */
  padding: 22px;
  width: 100%;
}

.description {
  color: #fff;
  opacity: 75%;
}

.nameLink {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.li-logo {
  margin-right: 13px;
  position: absolute;
  right: 0;
  bottom: 13px;
}
.bi {
  color: gray;
}
.bi:hover {
  color: #c9c7c7;
  transition: color 0.5s linear;
}

@media only screen and (max-width: 767px) {
  .main {
    height: 400px !important;
    width: 270px !important;
  }
  .profileheading {
    margin-bottom: 20px;
  }
  .personImg {
    height: 350px;
    width: 252px;
    margin-left: 7px;
  }
  .bg {
    height: 314px;
    width: 264px;
    border-radius: 25px;
  }
  .bgBorder {
    height: 304px;
    width: 254px;
    margin: 5px;
  }
  .profile {
    height: 174px;
    width: 264px;
    bottom: 0;
    left: 0;
  }
  .personName {
    width: 80%;
    bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
  .personDesignation {
    line-height: 15px;
    top: 142px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .myProfileSwiper {
    width: 100%;
  }
  .nameLink {
    align-items: start;
  }
  .paging {
    background: #fff;
    font-size: xx-large;
  }

  .profilepaging {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }
  .profile-btn {
    display: inline;
  }
  .profile-page {
    font-family: barlow;
    font-size: 20px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: -0.40002408623695374px;
    text-align: left;
    color: #fff;
    text-align: center;
    opacity: 75%;
  }
  .profile-btn {
    height: 70px;
    width: 70px;
    color: #fff;
  }
  .dark-left-container {
    display: none;
  }
  .dark-right-container {
    display: none;
  }
}

@media only screen and (min-height: 1024px) {
  .main {
    height: 50vh !important;
  }
}
