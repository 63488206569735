/* Main Container css */

.about-main-container {
  /* max-width: 1440px; */
  /* margin: 0 auto; */
  position: relative;
}

/* Hero Section css */

.about-hero-section {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 0px 120px;
  /* margin-top: 80px; */
  position: relative;
  z-index: 1;
  /* background: linear-gradient(to right, #8e69b237, #0f0cd642); */
}
.about-bg1 {
  position: absolute;
  content: "";
  left: 20%;
  top: 30%;
  width: 1030px;
  height: 330px;
  border-radius: 50%;
  background: linear-gradient(120deg, #8e69b2, #0f0cd6);
  filter: blur(500px);
  z-index: -2;
  scale: 1.2;
}
.journey-bg1 {
  position: absolute;
  content: "";
  right: 0;
  top: 30%;
  width: 1030px;
  height: 330px;
  border-radius: 50%;
  background: linear-gradient(90deg, #8e69b2, #0f0cd6);
  filter: blur(500px);
  z-index: -1;
  scale: 1.2;
}

.Leaders_Component {
  margin-bottom: 150px;
  margin-top: 80px;
  display: flex;
}
.about-hero-heading {
  font-family: barlow;
  font-size: 56px;
  font-weight: 700;
  line-height: 80.92px;
  word-spacing: 5px;
  /* max-width: 850px; */
  text-align: center;
  color: #fffff0;
  z-index: 1;
}
.high {
  color: #d0f601;
}

/* .unimg_1 {
  background-image: url("https://ik.imagekit.io/dzzp3vpnx/Others/underline2.svg?updatedAt=1724925557888");
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 10px 10px;
}
.unimg_2 {
  background-image: url("https://ik.imagekit.io/dzzp3vpnx/Others/underline1.svg?updatedAt=1724925557929");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-position: center bottom;
  padding: 5px 10px;
} */

.about-hero-desc {
  display: flex;
  padding: 10px 0px;
  font-family: barlow;
  font-size: 22px;
  font-weight: 200;
  line-height: normal;
  word-spacing: 5px;
  max-width: 800px;
  text-align: left;
  color: rgba(255, 255, 255, 0.877);
  z-index: 1;
}

.about-bg2 {
  display: flex;
  position: absolute;
  content: "";
  left: -10%;
  bottom: 10%;
  width: 930px;
  height: 330px;
  border-radius: 50%;
  background: linear-gradient(
    90deg,
    rgba(124, 15, 175, 0.692) 0%,
    rgba(157, 23, 110, 0.479) 94%
  );
  filter: blur(140px);
  z-index: 0;
  scale: 1.1;
  rotate: 180deg;
  opacity: 70%;
}

.about-bg3 {
  display: flex;
  position: absolute;
  content: "";
  left: 0;
  top: 10%;
  width: 730px;
  height: 800px;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    rgba(20, 51, 128, 0.53) 29%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: blur(140px);
  z-index: -1;
  scale: 1.2;
}

.about-bg4 {
  display: flex;
  position: absolute;
  content: "";
  top: 10;
  right: 0;
  width: 60%;
  height: 430px;
  border-radius: 90%;
  background: linear-gradient(
    180deg,
    rgba(20, 51, 128, 1) 0%,
    rgba(25, 12, 54, 1) 100%
  );
  filter: blur(140px);
  z-index: -1;
  scale: 1.5;
}

.JoinBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
}
.join {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.15);
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.joinImg {
  height: 25px;
  width: 25px;
  color: #f8f8ffef;
}
.joinImgClr {
  color: #d0f601;
}
.pointsDesc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.points {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px 15px;
}
.checkMark {
  height: 30px;
  width: 30px;
  /* margin-top: 10px; */
  color: #d0f601;
}
.checkText {
  font-family: jost;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  word-spacing: 5px;
  text-align: left;
  color: #f8f8ffb1;
}
.sparkle {
  display: flex;
  height: 20px;
  width: 30px;
  position: relative;
  margin-left: 150px;
}
.Link {
  text-decoration: none;
}
.joinTxt {
  font-family: jost;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  word-spacing: 5px;
  text-align: center;
  color: #f8f8ffef;
}
.joinTxtClr {
  color: #d0f601;
}
.image_content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
.c_img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
.c_img img {
  height: auto;
  width: 100%;
  opacity: 95%;
  /* border-radius: 30px 0px 30px 0px; */
  z-index: 2;
}
.txt {
  display: flex;
  position: absolute;
  font-family: jost;
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
  word-spacing: 5px;
  max-width: 800px;
  text-align: center;
  color: #fffff0;
  z-index: 3;
}

/* Journey Section css */

.about-journey-section {
  position: relative;
  padding: 50px 120px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  z-index: 1;
  /* margin-bottom: 90px; */
}

.about-journey-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desc_jrny_sec {
  display: flex;
  width: 100%;
}
.about-journey-heading {
  align-self: center;
  right: 10%;
  font-family: barlow;
  font-size: 56px;
  font-weight: 700;
  line-height: 80.92px;
  z-index: 1;
  color: white;
}
.journey_desc {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: barlow;
  font-size: 25px;
  font-weight: 100;
  line-height: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.877);
  z-index: 1;
}
.jrny_img_comp {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jrny_img_comp1 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.glass_img_comp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 40px;
  border-radius: 0px 30px 30px 30px;
}
.glass_img_comp img {
  height: 300px;
  width: 300px;
  border-left: 2px solid #d0f601;
  border-radius: 30px 30px 0px 30px;
}
.glass_img_comp1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 40px;
  border-radius: 30px 0px 30px 30px;
}
.glass_img_comp1 img {
  height: 300px;
  width: 300px;
  border-radius: 30px 30px 30px 0px;
  border-right: 2px solid #d0f601;
}
.about-img1 {
  align-self: flex-start;
  flex-shrink: 1;
}

.about-img1 img {
  width: 100%;
  height: auto;
}

.journey-highlighted {
  color: rgba(219, 255, 0, 1);
}

/* Founder Section css */

.about-founder {
  margin: 200px 0;
}

.about-founder-card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 100%;
  position: relative;
  z-index: 1;
}

.about-founder-text-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 30px;
  width: 50%;
}

.about-founder-heading {
  font-family: barlow;
  font-size: 34.04px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;

  color: rgba(255, 255, 255, 1);
}

.about-founder-content {
  font-family: barlow;
  font-size: 28px;
  font-weight: 300;
  line-height: 40.46px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

.about-founder-video {
  position: relative;
  border-radius: 30px;
  height: 0;
  width: 100%;
  padding-bottom: 36.25%;
  overflow: hidden;
}

.skeleton-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(90deg, #2b2b2b 25%, #282828 50%, #202020 75%);
  background-size: 200% 100%;
  animation: skeleton-animation 1s infinite linear;
}

@keyframes skeleton-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.founder-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Strength Css */
.our-strength,
.our-culture {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}
.our-strength-heading,
.our-culture-heading {
  font-family: barlow;
  font-size: 56px;
  font-weight: 700;
  line-height: 40.97px;
  color: rgba(255, 255, 255, 1);
}

.our-strength-content,
.our-culture-content {
  font-family: barlow;
  font-size: 24px;
  font-weight: 300;
  line-height: 28.99px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.about-team-gallery {
  max-width: 1440px;
  margin: 100px auto;
  overflow-x: hidden;
  position: relative;
  margin-bottom: 150px;
}
.dark-left-team-container {
  position: absolute;
  top: 30px;
  height: 470px;
  width: 140px;
  left: -1px;
  z-index: 4;
  rotate: 180deg;
  display: flex;
  background: linear-gradient(270deg, black, rgba(0, 0, 0, 0));
  -webkit-box-pack: center;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
}

.dark-right-team-container {
  position: absolute;
  top: 30px;
  height: 470px;
  width: 140px;
  right: -1px;
  z-index: 4;
  display: flex;
  background: linear-gradient(270deg, black, rgba(0, 0, 0, 0));
  -webkit-box-pack: center;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
}

@media screen and (max-width: 770px) {
  .about-bg1 {
    right: 50%;
  }
  .about-bg2 {
    display: none;
  }
  .about-bg3 {
    display: none;
  }
  .about-bg4 {
    left: 50%;
    scale: 2;
  }
  .unimg_1 {
    padding: 7px 0px;
  }
  .about-hero-section {
    padding: 0px 5vw;
    align-items: center;
    margin-top: 80px;
    height: auto;
  }
  .about-hero-heading {
    margin: 20px 0px 0px 0px;
    font-size: 27px;
    line-height: 130%;
    text-align: center;
  }
  .about-hero-desc {
    font-size: 20px;
    line-height: normal;
    text-align: left;
  }
  .image_content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .c_img img {
    height: auto;
    width: 100%;
    z-index: 2;
  }
  .txt {
    font-size: 20px;
    font-weight: 300;
    max-width: 270px;
    z-index: 3;
  }
  .checkText {
    font-size: 20px;
    font-weight: 400;
  }
  .about-path {
    display: none;
  }
  .about-journey-section {
    margin-inline: 20px;
    margin-bottom: 0px;
    padding: 50px 20px;
  }
  .about-journey-top {
    flex-direction: column;
  }
  .jrny_img_comp {
    justify-content: center;
  }
  .jrny_img_comp1 {
    justify-content: center;
  }
  .about-bg2 {
    bottom: 20%;
  }

  .about-journey-heading {
    align-self: center;
    font-size: 26px;
    line-height: normal;
  }
  .journey_desc {
    font-size: 20px;
    line-height: normal;
    text-align: center;
    text-align: left;
  }
  .desc_jrny_sec {
    flex-direction: column;
    gap: 50px;
  }
  .about-founder {
    padding: 20px;
  }
  .training-card-container {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }
  .about-founder-card-container {
    flex-direction: column;
    gap: 5px;
    height: 100%;
  }
  .glass_img_comp {
    padding: 20px;
    border-radius: 0px 20px 20px 20px;
  }
  .glass_img_comp img {
    height: 200px;
    width: 200px;
    border-radius: 20px 20px 0px 20px;
    border-left: none;
    border-top: 2px solid #d0f601;
  }
  .glass_img_comp1 {
    padding: 20px;
    border-radius: 20px 0px 20px 20px;
  }
  .glass_img_comp1 img {
    height: 200px;
    width: 200px;
    border-radius: 20px 20px 20px 0px;
    border-right: none;
    border-bottom: 2px solid #d0f601;
  }
  .about-founder-text-card {
    gap: 20px;
    border-radius: 30px;
    width: 100%;
  }
  .about-founder-video {
    width: 100%;
    padding-bottom: 57.25%;
    border-radius: 30px;
  }
  .about-founder-heading {
    font-size: 28px;
    line-height: 36px;
  }
  .about-founder-content {
    font-size: 20px;
    line-height: normal;
  }

  .our-strength,
  .our-culture {
    flex-direction: column;
    gap: 20px;
    margin-inline: 20px;
  }

  .our-culture-heading,
  .our-strength-heading {
    font-size: 26px;
    line-height: 30px;
  }

  .our-culture-content,
  .our-strength-content {
    font-size: 20px;
    line-height: normal;
  }

  .about-team {
    margin-bottom: 150px;
  }

  .about-team-gallery {
    margin-top: 0;
    margin-bottom: 100px;
  }
}
@media (min-width: 500px) and (max-width: 770px) {
  .about-hero-section {
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .about-hero-heading {
    margin: 0px 0px;
    font-size: 35px;
    line-height: 130%;
    text-align: center;
    max-width: 900px;
  }
  .about-hero-desc {
    font-size: 15px;
    line-height: normal;
    text-align: left;
    max-width: 500px;
  }
  .image_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .c_img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .c_img img {
    height: auto;
    width: 100%;
    border-radius: 10px 0px 10px 0px;
  }
  .txt {
    font-size: 30px;
    font-weight: 300;
    max-width: 400px;
  }
  .desc_jrny_sec {
    flex-direction: column;
    gap: 5px;
  }
  .journey_desc {
    font-size: 13px;
    font-weight: 100;
    padding: 0px;
    text-align: left;
    line-height: normal;
  }
  .jrny_img_comp {
    justify-content: flex-end;
  }
  .jrny_img_comp1 {
    justify-content: flex-start;
  }
  .glass_img_comp img {
    border-top: none;
    border-left: 2px solid #d0f601;
  }
  .glass_img_comp1 img {
    border-bottom: none;
    border-right: 2px solid #d0f601;
  }
  .our-strength-content {
    margin-top: 10px;
    font-weight: 500;
  }
  .desc_jrny_sec {
    flex-direction: row;
  }
}
@media (min-width: 770px) and (max-width: 1024px) {
  .about-hero-section {
    height: auto;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 0px 50px 0px 50px;
  }
  .about-hero-heading {
    font-size: 50px;
    line-height: 130%;
    text-align: center;
    /* max-width: 700px; */
  }
  .about-hero-desc {
    font-size: 17px;
    font-weight: 100px;
    line-height: normal;
    text-align: left;
    max-width: 500px;
  }
  .image_content {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }
  .c_img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .c_img img {
    height: auto;
    width: 100%;
    border-radius: 10px 0px 10px 0px;
  }
  .txt {
    font-size: 40px;
    font-weight: 300;
    max-width: 600px;
  }
  .journey_desc {
    font-size: 18px;
    font-weight: 100;
    padding: 0px;
    text-align: left;
    line-height: normal;
  }
  .about-journey-section {
    padding: 0px 50px;
    /* margin-top: 150px; */
  }
  .about-journey-heading {
    font-size: 30px;
    line-height: 50px;
  }
  .glass_img_comp {
    padding: 20px;
    border-radius: 0px 20px 20px 20px;
  }
  .glass_img_comp img {
    height: 200px;
    width: 200px;
    border-radius: 20px 20px 0px 20px;
  }
  .glass_img_comp1 {
    padding: 20px;
    border-radius: 20px 0px 20px 20px;
  }
  .glass_img_comp1 img {
    height: 200px;
    width: 200px;
    border-radius: 20px 20px 20px 0px;
  }
  .about-founder-card-container {
    flex-direction: column;
    gap: 20px;
    height: 100%;
    margin-inline: 50px;
  }
  .about-founder-text-card {
    width: 100%;
  }
  .about-founder-video {
    padding-bottom: 56.25%;
  }

  .our-strength,
  .our-culture {
    flex-direction: column;
    gap: 20px;
    margin-inline: 20px;
  }

  .our-culture-heading,
  .our-strength-heading {
    font-size: 40px;
    line-height: 50px;
  }

  .our-culture-content,
  .our-strength-content {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .about-hero-section {
    height: auto;
    align-items: center;
    justify-content: center;
    /* gap: 50px; */
    padding: 0px 50px;
    /* margin-top: 100px; */
    margin-bottom: 80px;
  }
  .about-hero-heading {
    font-size: 56px;
    line-height: 55px;
    /* max-width: 900px; */
  }
  .about-hero-desc {
    font-size: 25px;
    font-weight: 100px;
    line-height: normal;
    text-align: left;
    max-width: 600px;
  }
  .image_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
  }
  .c_img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .c_img img {
    height: auto;
    width: 100%;
    border-radius: 40px 0px 40px 0px;
  }
  .journey-svg {
    rotate: 10deg;
    top: 5%;
    width: 70%;
  }
  .about-journey-heading {
    font-size: 45px;
    line-height: 55px;
  }
  .about-founder {
    padding-inline: 50px;
  }
  .about-founder-video {
    padding-bottom: 33.25%;
  }
  .about-founder-text-card {
    width: 60%;
    gap: 20px;
  }
  .about-founder-heading {
    font-size: 30px;
    line-height: 37px;
  }
  .about-founder-content {
    font-size: 22px;
    line-height: initial;
  }

  .our-strength-heading,
  .our-culture-heading {
    font-size: 45px;
    line-height: 55px;
  }

  .our-strength-content,
  .our-culture-content {
    font-size: 24px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1440px) {
  .about-founder {
    padding-inline: 50px;
  }
  .journey-svg {
    width: 75%;
  }
}
