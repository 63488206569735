.carousel-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  row-gap: 30px;
  overflow-x: hidden;
  margin-top: 31px;
  /* height: 94vh; */
}

.car-image-container {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 20px;
  flex-shrink: none;
  border-radius: 20px;
}

.car-image {
  height: 100%;
  border-radius: 20px;
}


.car-image-wrapper {
  display: flex;
  width: 100vw;
  gap: 20px;
}

.car-image-wrapper:hover {
  border-radius: 35px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.car-image {
  height: 469px;
}

.car-image:hover {
  /* scale: 1.2; */
  transition: 0.2s linear;
  animation-play-state: paused;
}

.car-image img {
  height: 100%;
  width: 100%;
}

.car-image-wrapper{
  animation: scrollForward 150s linear infinite;
}

@media only screen and (min-height: 1024px){
  .car-image{
    height: 30vh;
  }
}

@keyframes scrollForward {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  50.0001% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
