/* Calendar */
:root {
  --holiday-date-color: rgba(240, 100, 101, 1);
  --selected-date-color: #dbff00;
}

.calendar-wrapper {
  background-color: rgba(225, 225, 225, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  color: white;
  max-width: 300px;
  height: 350px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  font-style: normal;
  flex-direction: column;
  font-weight: 300;
  font-family: "barlow", sans-serif;
}

.calendar-body-wrapper {
  padding: 17px;
}

.calendar-header {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  margin-bottom: 20px;
  justify-items: center;
  align-items: end;
}

.calendar-next-button, .calendar-prev-button {
  cursor: pointer;
  opacity: 60%;
}
.calendar-next-button:hover, .calendar-prev-button:hover {
  opacity: 100%;
}

.calendar-body .table-header {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(7, 1fr);
}

.holidayName {
  padding-top: 15px;
  color: var(--holiday-date-color);
  margin: 0;
}

.weekday p{
  font-size: 0.8rem;
  font-weight: 300;
}

/* Calendar Day */

.calendar-day-wrapper.table-content {
  font-size: 0.8rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  place-items: center;
  gap: 5px;
}


.calendar-day .current p {
  color: #fff;
}


.calendar-day .selected p {
  color: var(--holiday-date-color) !important;
  font-weight: bold;
}

.calendar-day {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
}

.calendar-day p {
  margin: 0;
  color: #a6a6a6;
}

.calendar-day:hover {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}

.current p {
  color: #fff;
  font-weight: 200;
}

.today p{
  color: rgba(219, 255, 0, 1);
  font-weight: 400;
 }

.selectedDate {
  border-radius: 26.725px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.75);
  /* border: 1px solid #dbff00; */
  border-radius: 50%;
}

.selectedDate p {
  color: #000;
  font-weight: 400;
}

.holiday p {
  font-weight: bolder;
  color: var(--holiday-date-color);
}

.mobilefor{
  display: none;

}

@media only screen and (max-width: 770px) {
  .mobilefor{
    display: block;
    width: auto;
    padding: 5px 25px;
  }

  .calendar-wrapper {
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
  }

  .calendar-body-wrapper {
    padding: 10px 0;
    overflow: hidden;
  }
  .calendar-body-wrapper ul{
    margin: 5px;
    display: inline-flex;
    list-style: none;
    white-space: nowrap;
    animation: scroll infinite linear;
    /* animation-fill-mode: both; */
    animation-delay: 1s;
  }

  .calendar-body-wrapper ul li::after{
    content: '';
    color: white;
    opacity: 50%;
    margin: 0 20px;
  }

  .calendar-body-wrapper ul li:last-child::after{
    content: '';
  }

  .mobile-calendar-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cal-heading{
    font-family: "barlow","sans-serif";
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

  }

}

@media screen and (min-width: 1024px) and (max-width: 1920px) {
  .calendar-wrapper {
    width: 100%;
  }
}

@keyframes scroll {
  from {
      transform: translateX(100%);
  }

  to {
      transform: translateX(-100%);

  }
}