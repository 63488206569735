.blog_page {
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 0 50px 80px;
}
.blog-bg-1 {
  display: flex;
  position: absolute;
  content: "";
  height: 330px;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 20%;
  z-index: 0;
  scale: 1.3;
  background: linear-gradient(
    90deg,
    rgb(46, 21, 139) 43%,
    rgb(71, 39, 99) 63%
  );
  filter: blur(140px);
}

.Service_Header {
  letter-spacing: 1px;
  font-family: barlow;
  font-size: 56px;
  font-weight: 700;
  line-height: 69.36px;
  padding-top: 110px;
  padding-bottom: 60px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.noblog {
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: 335px;
  width: 98%;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  color: #888;
  grid-column: 1 / -1;
}

.noblog img {
  height: 40px;
  width: 40px;
}

.BlogSelected {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 40px;
}

.custom-button {
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: "barlow", sans-serif;
  padding: 6px 14.09px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.custom-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffff;
  transition: 0.8s;
}
.custom-button-selected {
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 30px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: "barlow", sans-serif;
  padding: 6px 14.09px;
  font-weight: 600;
  font-size: 14px;
  color: black;
  background-color: #ffff;
}

.card_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 50px;
  column-gap: 30px;
  max-width: 1440px;
}

.blog-card-link {
  border: 1px solid rgba(255, 255, 255, 0.15);
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding-bottom: 10px;
  overflow: hidden;
}

.blog-img-container{
  position: relative;
  overflow: hidden;
  border-radius: 30px 30px 0 0;

}

.blog-card-img {
  width: 100%;
  object-fit: cover;
  border-radius: 30px 30px 0 0;
}

.blog-img-hovered {
  scale: 1.1;
  transition: 2s;
}

.tech-date-container {
  position: relative;
  padding-inline: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}

.blogcard-tech {
  letter-spacing: 1px;
  border-radius: 4px;
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 6px 12px;
  font-family: barlow;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}

.blogcard-date {
  letter-spacing: 1px;
  font-size: 14px;
  font-family: barlow;
  font-weight: 300;
  height: 100%;
  color: rgba(255, 255, 255, 0.7);
}

.blogcard-headline {
  padding: 15px 20px 0;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  line-height: 30px;
  color: white;
  font-family: barlow;
  letter-spacing: 1px;
}

@media screen and (max-width: 600px) {
  .card_container {
    grid-template-columns: 1fr !important;
  }
  .noblog {
    text-align: justify;
  }
  .noblog img {
    margin-inline: auto;
  }
}

@media screen and (max-width: 770px) {
  .blog_page {
    position: relative;
    margin: 0 20px 80px;
  }
  .Service_Header {
    font-size: 32px;
    line-height: normal;
  }
  .card_container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 770px) and (max-width: 1024px) {
  .card_container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) and (max-width: 1441px) {
  .card_container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1440px) {
  .blog_page {
    max-width: 1440px;
    margin-inline: auto;
    position: relative;
    margin-bottom: 80px;
    padding-inline: 50px;
  }
  .card_container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
