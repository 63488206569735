.carousel-pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  height: max-content;
  row-gap: 30px;
  overflow-x: hidden;
}

.lang-image>img {
  width: 270px !important;
  opacity: 40%;
  
  position: relative;
  z-index: 1;
}

.lang-image>img:hover{
  opacity: 100%;

}
/* 
.car-image-wrapper{
  animation: scrollForward 50s linear infinite;
} */


@media only screen and (max-width: 768px){
  .lang-image>img {
    width: 200px !important;
  }
  /* .car-image-wrapper{
    animation: scrollForward 20s linear infinite;
  } */

}

/* 
@keyframes scrollForward {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2180px);
  }
} */