.homeservice-main {
  margin-top: 110px;
  max-width: 1440px;
  /* overflow-x: hidden; */
  margin-inline: auto;
  padding: 0 40px;
  height: max-content;
  position: relative;
}

.service-bg1 {
  display: flex;
  position: absolute;
  content: "";
  right: 0;
  top: 40%;
  width: 930px;
  height: 530px;
  border-radius: 50%;
  background: linear-gradient(
    90deg,
    rgba(124, 15, 175, 1) 43%,
    rgba(152, 22, 120, 1) 63%
  );
  filter: blur(140px);
  z-index: 0;
  scale: 1.5;
  rotate: 180deg;
  opacity: 70%;
}

.homeservice-heading {
  font-family: Barlow;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.4008667767047882px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 55px;
  position: relative;
  z-index: 1;
}

.main-service-slider {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  width: 100%;
  padding: 20px;
}

.main-service-slider-nav {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 20px 40px 60px;
  border-radius: 30px;
}

.main-service-slider-nav-item {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid gray;
  cursor: pointer;
  position: relative;
  font-family: barlow;
  font-size: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

.main-service-slider-nav .slide-active {
  color: #fff;
}

.main-service-slider-nav .paused {
  border-bottom: 1px solid #dbff00;
}

.main-service-slider-nav-item .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.5px;
  background: #dbff00;
  animation: progress 10s linear infinite;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.mobile-chip-nav {
  display: none;
}

.main-service-slider-content {
  background: rgba(255, 255, 255, 0.15);
  padding: 20px;
  width: 100%;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.main-service-slider-content-item {
  z-index: 1;
  transition: opacity 1s ease-in-out;
  display: none;
  /* position: absolute; */
  font-family: barlow;
  font-size: 27.38px;
  font-weight: 500;
  letter-spacing: -0.33016982674598694px;
  text-align: left;
}

.main-service-slider-content-item img {
  width: 80%;
  margin: 0px auto 40px;
}

.main-service-slider-content-item.slide-active {
  display: block;
}

.main-service-slider-content-item-text {
  width: 90%;
  margin-inline: auto;
  text-align: left;
}
.main-service-slider-content-item-image {
  display: flex;
  width: fit-content;
  margin-inline: auto;
}

@media screen and (max-width: 767px) {
  .homeservice-main {
    padding: 0 20px;
  }
  .main-service-slider {
    grid-template-columns: 1fr;
    padding: 0;
  }
  .main-service-slider-nav {
    display: none;
  }
  .main-service-slider-content {
    padding: 40px 20px 20px;
  }
  .main-service-slider-content-item img {
    width: 60%;
  }
  .main-service-slider-content-item {
    height: 380px;
    font-size: 16px;
  }
  .homeservice-heading {
    font-size: 32px;
    line-height: 50px;
  }
  .mobile-chip-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    z-index: 1;
  }
  .mobile-chip-nav .mobile-chip-nav-item {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-align: center;
    border-radius: 15px;
    position: relative;
    z-index: 1;
  }
  .mobile-chip-nav .mobile-chip-nav-item.slide-active {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .main-service-slider-nav-item {
    font-size: 24px;
  }
  .main-service-slider-content {
    order: 1;
  }
  .main-service-slider-content-item {
    font-size: 24px;
  }
  .homeservice-heading {
    font-size: 40px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .main-service-slider {
    padding: 10px;
  }
}
