footer {
  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  max-width: 1440px;
  margin-inline: auto;
  margin-bottom: 50px;
  border-radius: 30px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}

.footer-top {
  display: flex;
  justify-content: space-between;
}

.footer-bottom {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 0.25px solid rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: space-between;
}

.footer-bg1 {
  display: flex;
  position: absolute;
  content: "";
  right: 0;
  left: 0;
  margin-inline: auto;
  top: 100%;
  width: 50vw;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
    90deg,
    rgba(124, 15, 175, 1) 43%,
    rgba(152, 22, 120, 1) 63%,
    transparent 100%
  );
  filter: blur(100px);
  z-index: 1;
  scale: 1;
}
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-squbix {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.footer-squbix-logo {
  display: flex;
  align-items: center;
}
.footer-squbix-logo img {
  opacity: 60%;
}

.footer-squbix-logo-text {
  font-family: barlow;
  font-size: 19.05px;
  font-weight: 700;
  line-height: 17.85px;
  text-align: left;
  display: inline-block;
}

.footer-isoMark {
  display: flex;
  align-items: center;
  padding-left: 3px;
  gap: 15px;
}
.footer-isoMark img {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-color: #149dff;
  filter: grayscale(95%);
}

.footer-isoMark img:hover {
  filter: grayscale(0%);
}

.footer-squbix-location {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-squbix-location span {
  font-weight: 200;
}

.footer-copyright {
  font-weight: 200;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-right {
  display: flex;
  gap: 80px;
}
.footer-quick-link {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 15px;
}
.footer-heading {
  font-family: barlow;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  color: rgb(255, 255, 255);
}

.footer-link {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  text-align: left;
  gap: 5px;
}

.footer-link > * {
  font-family: barlow;
  font-weight: 400;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

.footer-link > *:hover {
  color: #dbff00;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 15px;
}

.footer-email {
  font-family: barlow;
  font-weight: 400;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

.footer-email:hover {
  color: #dbff00;
}

.footer-social {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.footer-social > * {
  color: rgba(255, 255, 255, 0.5);
}

.footer-social > *:hover {
  color: #dbff00;
}

@media screen and (max-width: 482px) {
  .footer-left {
    flex-direction: column !important;
    gap: 20px;
  }
  .footer-right {
    flex-direction: column;
  }
  .footer-isoMark img {
    height: 40px;
    width: 40px;
  }
  .footer-bottom {
    margin-top: 30px;
    padding-top: 30px;
    flex-direction: column-reverse;
    gap: 20px;
  }

  /* .footer-copyright{
    font-size: 12px;
  } */
  .footer-squbix-location span {
    font-size: 14px;
  }
}

@media screen and (max-width: 740px) {
  footer {
    padding: 20px;
    margin-inline: 20px !important;
    margin-bottom: 12px;
  }

  .footer-top {
    flex-direction: column-reverse;
  }

  .footer-left {
    flex-direction: row;
  }

  .footer-isoMark img {
    height: 50px;
    width: 50px;
  }

  .footer-right {
    gap: 50px;
    margin: 0 10px 40px 0;
  }
}

@media screen and (min-width: 740px) and (max-width: 870px) {
  footer {
    margin-inline: 20px !important;
  }
  .footer-right {
    gap: 20px;
    margin-right: 10px;
  }
  .footer-isoMark img {
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-width: 1440px) {
  footer {
    margin-inline: 50px;
  }
}
