.main-section-ethnicart {
  max-width: 1440px;
  margin-inline: auto;
  padding-top: 50px;
}
.title {
  font-family: "Barlow";
  font-size: 128px;
  font-weight: 400;
  line-height: 160px;
  text-align: left;
}
.main-bg {
  width: 80%;
  height: 80%;
}
.main-title {
  font-family: "Barlow";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 0.04em;
  text-align: left;
}
.sub-title {
  color: #dfda75;
}
.bold-container {
  display: flex;
}

.bold-section {
  margin-top: 299px;
  display: flex;
  justify-content: center;
}
.bold-right {
  padding-left: 20px;
}
.size-chart {
  padding-top: 255px;
}
.size-chart-container {
  padding-left: 112px;
  padding-right: 112px;
}
/* .size-chart-img{
  width: 50%;
  height: 50%;
} */
.second-img {
  width: 100%;
  height: 100%;
}
.second-section {
  margin-top: 402px;
}
.curated-title {
  font-family: "Barlow";
  font-size: 114px;
  font-weight: 700;
  line-height: 142.5px;
  text-align: left;
  color: #e9e9e9;
}

.right-title {
  font-family: "Barlow";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  text-align: left;
  color: #cccccc;
}
.right-subtitle {
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #f4ba5d;
}
.size-title {
  font-family: "Barlow";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  text-align: left;
  color: #cccccc;
}
.advance-filter {
  margin-top: 354px;
}
.advance-container {
  padding-left: 112px;
  padding-right: 112px;
}
.advance {
  padding-right: 237px;
}
.size-subtitle {
  margin-top: 29px;
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
}
.advance-title {
  font-family: "Barlow";
  font-size: 64px;
  font-weight: 400;
  line-height: 80px;
  text-align: left;
  color: #cccccc;
  padding-top: 62px;
}
.brand-section {
  padding-left: 112px;
  padding-right: 112px;
}
.advance-subtitle {
  padding-top: 29px;
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
}
.brand-title {
  font-family: "Barlow";
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  color: #ffd542;
}
.brand-subtitle {
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  padding-top: 10px;
}
.brand-container {
  padding-top: 242px;
}
.wishlist-section {
  padding-top: 250px;
}
.wishlist-container {
  padding-left: 124px;
  padding-right: 124px;
}
.wishlist-title {
  font-family: "Barlow";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  text-align: left;
  color: #cccccc;
}
.wishlist-subtitle {
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding-top: 34px;
  padding-right: 200px;
  color: #ffffff;
}
.order {
  padding-top: 325px;
}
.order-title {
  font-family: "Barlow";
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  color: #cccccc;
}
.odrder-subtitle {
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  padding-top: 13px;
  padding-bottom: 71px;
}
.social {
  padding-top: 250px;
}
.social-left {
  padding-right: 124px;
  padding-left: 124px;
}
.social-title {
  font-family: "Barlow";
  font-size: 96px;
  font-weight: 700;
  line-height: 120px;
  text-align: left;
  color: #cccccc;
}
.social-subtitle {
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  padding-top: 27px;
}
.review {
  padding-left: 124px;
  padding-top: 250px;
}
.review-title {
  font-family: "Barlow";
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;

  color: #cccccc;
}
.review-subtitle {
  font-family: "Barlow";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  padding-top: 22px;
}
.review-img-container {
  padding-top: 72px;
  padding-bottom: 72px;
}
.dev {
  display: block;
}
.mobile {
  display: none;
}
@media (max-width: 600px) {
  section{
    padding-inline: 20px;
  }
  .main-section-ethnicart {
    width: 100vw;
    overflow-x: hidden;
  }
  .bold-section {
    margin-top: 88px;
    display: flex;
    justify-content: center;
  }
  .mobile {
    display: block;
  }
  .dev {
    display: none;
  }
  .title {
    font-family: "Barlow";
    font-size: 50px;
    font-weight: 400;
    line-height: 160px;
    text-align: left;
  }
  .main-bg {
    width: 100%;
  }
  .main-title {
    font-family: "Barlow";
    font-size: 25px;
    line-height: normal;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .second-section {
    margin-top: 100px;
  }
  .curated-title {
    padding-top: 8vw;
    font-size: 32px;
    line-height: normal;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 8px;
  }

  .second-img {
    width: 100%;
    scale: 2;
    position: relative;
    left: 30vw;
  }
  .bold-container {
    flex-direction: column;
  }
  .bold-right {
    padding-left: 0px;
  }
  .right-title {
    font-family: "Barlow";
    font-size: 40px;
    font-weight: 700;
    line-height: 80px;
    text-align: center;
    color: #cccccc;
  }
  .right-subtitle {
    font-family: "Barlow";
    font-size: 15px;
    font-weight: 400;
    line-height: 0px;
    text-align: center;
    color: #cccccc;
  }
  .size-chart {
    padding-top: 150px;
  }
  .size-chart-container {
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column-reverse;
  }
  .size-chart-img {
    width: 100%;
    /* height: 50%; */
  }
  .size-title {
    font-family: "Barlow";
    font-size: 30px;
    line-height: 38.13px;
    text-align: center;
    color: #cccccc;
  }
  .size-subtitle {
    margin-top: 29px;
    font-family: "Barlow";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
  .advance-filter {
    margin-top: 100px;
  }
  .advance-container {
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    gap: 10px;
  }
  .advance {
    padding-right: 0px;
  }
  .advance-title {
    font-family: "Barlow";
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #cccccc;
    padding-top: 62px;
    padding-bottom: 56px;
  }
  .advance-subtitle {
    font-family: Barlow;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    padding-inline: 10px;
  }
  .advance-subtitle span {
    color: #fff;
  }
  .advance-img {
    width: 100%;
    padding-inline: 10px;
  }

  .brand-container {
    padding-top: 120px;
  }
  .brand-section {
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
  }
  .brand-img {
    width: 100%;
    padding-inline: 10px;
  }
  .brand-title {
    font-family: Barlow;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: white;
    padding-inline: 10px;
  }
  .brand-subtitle {
    font-family: Barlow;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    color: #ffffff;
    padding-top: 10px;
    padding-inline: 10px;
  }
  .wishlist-section {
    padding-top: 150px;
    flex-direction: column;
  }
  .wishlist-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .wishlist-img {
    width: 100%;
    padding-left: 10px;
    padding-top: 10%;
  }
  .wishlist-title {
    font-family: "Barlow";
    font-size: 30px;
    line-height: normal;
    font-weight: 700;
    text-align: center;
    color: #fff;
  }
  .wishlist-subtitle {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;

    padding-top: 24px;
    padding-right: 0px;
    color: rgba(255, 255, 255, 0.7);
  }
  .wishlist-subtitle span {
    color: #fff;
  }
  .order {
    padding-top: 150px;
  }
  .order-title {
    font-size: 30px;
    line-height: 40px;
  }
  .odrder-subtitle {
    font-family: "Barlow";
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
    padding-top: 13px;
    padding-bottom: 50px;
  }
  .odrder-subtitle span {
    color: #fff;
  }
  .social {
    padding-top: 150px;
    flex-direction: column;
  }
  .social-left {
    /* padding-right: 12px; */
    padding-left: 10px;
    padding-right: 10px;
  }
  .social-title {
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #cccccc;
    padding-bottom: 40px;
  }
  .social-subtitle {
    font-family: "Barlow";
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    padding-top: 27px;
  }
  .social-subtitle span {
    color: #fff;
  }
  .social-img {
    width: 100%;
    padding-top: 10%;
    padding-left: 10px;
  }
  .review {
    padding-left: 10px;
    padding-top: 150px;
  }
  .review-img {
    width: 100%;
    padding-top: 10%;
  }
  .review-img-container {
    padding-top: 0%;
    padding-bottom: 10%;
  }
  .review-title {
    font-family: Barlow;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
  .review-subtitle {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    padding: 22px 0;
  }
  .review-subtitle span {
    color: #fff;
  }
}
