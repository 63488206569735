.services-style {
  padding-top: 12vh;
  background: transparent;
}

.ServiceSelected {
    margin: -4vh 2vw 0vh;
    display: flex;
    flex-direction: row;
    gap: 3vw;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.services-section {
  display: flex;
  flex-direction: column;
  padding: 3vh;
}

.services-section-r {
  display: flex;
  flex-direction: column;
  margin: 20px;
  text-align: center;
}

.services-section-r-1 {
  font-family: "barlow", sans-serif;
  font-size: 34.19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: white;
  padding-bottom: 30px;
}

.services-section-r-2 {
  color: rgba(255, 255, 255, 0.5);
  font-family: "barlow", sans-serif;
  font-size: 20.568px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.custom-btn {
  outline: none;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.5);
  font-family: "barlow", sans-serif;
  background: none;
  padding: 0px 14.09px;
  height: 37.574px;
  color: rgba(255, 255, 255, 0.5);
}

.custom-btn-selected {
  outline: none;
  font-family: "barlow", sans-serif;
  border-radius: 10px;
  color: black;
  border: rgba(255, 255, 255, 0.5) 1px;
  padding: 0px 14.09px;
  height: 37.574px;
  background-color: #c6c6c6;
}

.custom-btn-test-auto:hover,
.custom-btn-test-auto:active {
  border: white;
  color: white;
  background-color: #a7c957;
}

.services-section-l {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  padding-right: 30px;
}

.services-section-l img {
  border-radius: 11.034px;
  width: 100%;
}



@media only screen and (max-width: 767px) {
  .ServiceSelected{
    margin-top: 1vw;
    gap: 12px;
  }
  .services-section{
    padding: 0;
    padding-top: 3vh;
  }
  .services-section-l{
    width: 100%;
    height: 70vh;
    padding: 0;
  }
  .custom-btn,.custom-btn-selected{
    width: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .services-style{
    height: 126vh;
  }
  .ServiceSelected{
    gap: 22px;
  }
  .services-section{
    flex-direction: column;
  }
  .services-section-l{

    width: 100%;
  }
  .services-section-r{
    width: 100%;
  }
  .services-section-r-1{
    text-align: center;
  }
}
@media only screen and (min-width: 1441px){
  .services-style{
    width: 1440px;
    margin: 0 auto;
  }
} 
 
  /* .prodHeading {
   margin-top: 50px;
   text-align: center;
   font-family: "barlow";
   color: #fff;
   font-size: 24px;
   font-weight: 200;
   line-height: 32px;
   letter-spacing: 0px;
   opacity: 75%;
 }
.offers{
  margin-top: 5rem;
   text-align: center;
   font-family: "barlow";
   font-size: 24px;
   font-weight: 200;
}
 .offersHeading {
   margin-top: 15rem;
   text-align: center;
   font-family: "barlow";
   color: #DBFF00;
   font-size: 40px;
   font-weight: 700;
   line-height: 32px;
   letter-spacing: 0px;
   opacity: 75%;
 }
.offersHeading span{
 color: #F8FDDB;
}
 .offersDetails {
  margin-top:3rem;
   text-align: center;
   font-family: "barlow";
   font-size: 24px;
   line-height: 40px;
   letter-spacing: 0.1666666716337204px;
   color: rgba(255, 255, 255, 1);
   } */