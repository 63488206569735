.AboutLeader {
  position: relative;
  background-color: #391a4380;
  background: linear-gradient(215deg, #130f40 0%, #000000 74%);
  height: 100dvh;
  color: white;
  z-index: 1;
}
.about-bg1 {
  position: absolute;
  content: "";
  right: 20%;
  top: 50%;
  width: auto;
  height: 330px;
  border-radius: 50%;
  background: linear-gradient(120deg, #8e69b2, #0f0cd6);
  filter: blur(500px);
  z-index: -1;
  scale: 1.2;
}
.LeaderComponent {
  display: flex;
  padding: 50px 25px 0px 25px;
  height: 100%;
  width: 100%;
}
.ProfileCard {
  flex: 1.5;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.P_Card {
  display: flex;
  min-width: 400px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
  padding: 15px;
  flex-direction: column;
  border-radius: 20px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  gap: 15px;
}

.P_Image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Image {
  height: 300px;
  width: 300px;
  border-radius: 9%;
  object-fit: cover;
  background-color: #252849a6;
}
.P_Desc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.active_i {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.active_i img {
  height: 100px;
  width: 100px;
}
.desc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.name {
  font-family: barlow;
  font-size: 30px;
  line-height: normal;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-wrap: wrap;
  text-align: center;
}

.desg {
  font-family: barlow;
  font-size: 20px;
  line-height: normal;
  font-weight: 400;
  color: rgb(223, 223, 223);
  text-wrap: wrap;
  text-align: center;
}
.P_loc {
  font-family: barlow;
  font-size: 17px;
  line-height: normal;
  font-weight: 300;
  color: rgb(223, 223, 223);
  text-wrap: wrap;
  text-align: center;
}
.switchComp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.switch_P {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 10px 15px 10px 15px;
  background: rgba(255, 255, 255, 0.15);
  /* background-color: rgba(255, 255, 255, 0.1); */
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  /* border: 2px solid rgba(255, 255, 255, 0.4);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  background-image: url("https://ik.imagekit.io/dzzp3vpnx/Others/Rectangle.png?updatedAt=1724389203239");
  background-size: cover;
  background-blend-mode: overlay; */
  gap: 15px;
}

.switchImage {
  display: flex;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background: linear-gradient(45deg, #c58bff23, #dd15ef28);
  filter: grayscale(100%);
  box-shadow: 5px 5px 3px #0a080c8c, -1px -1px 3px #cea1ff9c;
  transition: transform 0.2s ease-out;
}

.activeImage {
  display: flex;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background: linear-gradient(45deg, #c58bffda, #dd15efda);
  box-shadow: 5px 5px 3px #0a080c, -1.5px -1.5px 3px #f3e8ff;
  transition: transform 0.2s ease-out;
}

.switchImage:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 5px 5px 3px #0a080c8c, -1.5px -1.5px 3px #cea1ff9c;
  transition: transform 0.3s ease-out;
}

.DescriptCard {
  flex: 3;
  /* padding: 40px 20px 40px 10px; */
  margin: 40px 40px 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.desc_glass {
  display: flex;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.15);
  /* background-color: rgba(255, 255, 255, 0.1); */
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  /* border: 2px solid rgba(255, 255, 255, 0.4); */
  /* border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  background-image: url("https://ik.imagekit.io/dzzp3vpnx/Others/Rectangle.png?updatedAt=1724389203239");
  background-size: cover;
  background-blend-mode: overlay; */
}

.Desc_Glass1 {
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  gap: 40px;
}

.P_summary {
  font-family: barlow;
  font-size: 23px;
  line-height: normal;
  font-weight: 200;
  color: rgba(218, 218, 218, 0.749);
  text-wrap: wrap;
  text-align: left;
  line-height: 35px;
}

.split {
  flex: 1;
  display: flex;
  gap: 40px;
}

.P_skill_summary {
  font-family: barlow;
  font-size: 23px;
  line-height: normal;
  font-weight: 400;
  color: rgb(255, 255, 255);
  text-wrap: wrap;
  text-align: left;
  line-height: 35px;
}
.certify_head {
  font-family: barlow;
  font-size: 28px;
  font-weight: 500;
  color: rgba(193, 193, 193, 0.717);
  text-wrap: wrap;
  text-align: left;
  line-height: 35px;
}
.split_Glass1 {
  flex: 2.8;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 35px;
}
.cert_desc {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.cert_desc_text {
  font-family: barlow;
  font-size: 23px;
  line-height: normal;
  font-weight: 300;
  color: rgb(255, 255, 255);
  text-wrap: wrap;
  text-align: left;
  line-height: 35px;
}
.split_Glass2 {
  flex: 1.2;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
}

.li_nk {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 75%;
  justify-content: space-between;
  box-shadow: 2px 3px 4px #4444448c, -3px -3px 3px #4545459c;
  border-radius: 30px;
  cursor: pointer;
  background: linear-gradient(90deg, #2c1b5699, #1f0a5677);
  text-decoration: none;
  color: rgba(255, 255, 255, 0.635);
}
.ic_on {
  display: flex;
  height: 30px;
  width: 30px;
}
.te_xt {
  display: flex;
  align-items: flex-start;
  text-align: center;
  font-family: barlow;
  font-size: 23px;
  line-height: normal;
  font-weight: 300;
  color: rgb(255, 255, 255);
  text-wrap: wrap;
  line-height: 35px;
}

.li_nk1:hover {
  color: #cee730;
  box-shadow: 3px 4px 3px #000000a2, -3px -3px 3px rgba(255, 255, 255, 0.304);
}

.li_nk2:hover {
  color: #cee730;
  box-shadow: 3px 4px 3px #000000a2, -3px -3px 3px rgba(255, 255, 255, 0.304);
}

@media screen and (min-width: 1px) and (max-width: 1250px) {
  .AboutLeader {
    height: 100%;
  }
  .LeaderComponent {
    flex-direction: column;
    padding: 50px 25px 50px 25px;
  }
  .P_Card {
    min-width: 300px;
    padding: 5px;
  }
  .Image {
    height: 200px;
    width: 200px;
  }
  .switch_P {
    padding: 15px 20px 15px 20px;
  }
  .switchImage,
  .activeImage {
    height: 40px;
    width: 40px;
  }
  .name {
    font-size: 20px;
  }
  .desg {
    font-size: 9px;
  }
  .DescriptCard {
    margin: 40px 0px 0px 0px;
    align-items: center;
  }
  .P_loc {
    font-size: 7px;
  }
  .P_summary {
    font-size: 13px;
    line-height: normal;
  }
  .P_skill_summary {
    font-size: 13px;
    line-height: normal;
  }
  .split {
    flex-direction: column;
    gap: 40px;
  }
  .certify_head {
    font-size: 13px;
    line-height: normal;
  }
  .cert_desc_text {
    font-size: 13px;
    line-height: normal;
  }
  .ic_on {
    height: 18px;
    width: 18px;
  }
  .split_Glass2 {
    padding: 15px 5px;
  }
  .te_xt {
    font-size: 15px;
    line-height: normal;
  }
}

@media screen and (min-width: 321px) and (max-width: 500px) {
  .Image {
    height: 250px;
    width: 250px;
  }
  .P_Card {
    min-width: 350px;
    padding: 5px;
  }
  .Desc_Glass1 {
    min-width: 350px;
    margin: 0px 30px 0px 30px;
  }
  .desc_glass {
    max-width: 350px;
  }
  .switchImage,
  .activeImage {
    height: 60px;
    width: 60px;
  }
  .name {
    font-size: 27px;
  }
  .desg {
    font-size: 18px;
  }
  .P_loc {
    font-size: 15px;
  }
  .P_summary {
    font-size: 18px;
  }
  .P_skill_summary {
    font-size: 18px;
  }
  .certify_head {
    font-size: 18px;
  }
  .cert_desc_text {
    font-size: 18px;
  }
  .ic_on {
    height: 22px;
    width: 22px;
  }
  .split_Glass2 {
    padding: 15px 5px;
  }
}

@media screen and (min-width: 500px) and (max-width: 650px) {
  .Image {
    height: 300px;
    width: 300px;
  }
  .switchImage,
  .activeImage {
    height: 60px;
    width: 60px;
  }
  .name {
    font-size: 30px;
  }
  .desg {
    font-size: 23px;
  }
  .P_loc {
    font-size: 18px;
  }
  .P_Card {
    min-width: 400px;
  }
  .P_summary {
    font-size: 20px;
  }
  .P_skill_summary {
    font-size: 20px;
  }
  .desc_glass {
    max-width: 500px;
    margin: 0px 40px 0px 40px;
  }
  .certify_head {
    font-size: 20px;
  }
  .cert_desc_text {
    font-size: 20px;
  }
  .ic_on {
    height: 25px;
    width: 25px;
  }
  .li_nk {
    width: 65%;
  }
}

@media screen and (min-width: 650px) and (max-width: 850px) {
  .ProfileCard {
    margin-top: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .switch_P {
    flex-direction: column;
  }
  .Image {
    height: 300px;
    width: 300px;
  }
  .switchImage,
  .activeImage {
    height: 65px;
    width: 65px;
  }
  .desc_glass {
    min-width: 650px;
  }
  .name {
    font-size: 35px;
  }
  .desg {
    font-size: 20px;
  }
  .P_loc {
    font-size: 19px;
  }
  .P_Card {
    min-width: 400px;
  }
  .P_summary {
    font-size: 24px;
  }
  .P_skill_summary {
    font-size: 24px;
  }
  .Desc_Glass1 {
    max-width: 600px;
    margin: 0px 40px 0px 40px;
  }
  .certify_head {
    font-size: 25px;
  }
  .cert_desc_text {
    font-size: 25px;
  }
  .ic_on {
    height: 30px;
    width: 30px;
  }
  .li_nk {
    width: 60%;
    padding: 20px 25px;
  }
  .te_xt {
    font-size: 20px;
    line-height: normal;
  }
}

@media screen and (min-width: 850px) and (max-width: 1025px) {
  .ProfileCard {
    margin-top: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  .switch_P {
    flex-direction: column;
  }
  .Image {
    height: 350px;
    width: 350px;
  }
  .switchImage,
  .activeImage {
    height: 70px;
    width: 70px;
  }
  .desc_glass {
    min-width: 800px;
  }
  .name {
    font-size: 38px;
  }
  .desg {
    font-size: 23px;
  }
  .P_loc {
    font-size: 19px;
  }
  .P_Card {
    min-width: 500px;
  }
  .P_summary {
    font-size: 24px;
  }
  .P_skill_summary {
    font-size: 24px;
  }
  .Desc_Glass1 {
    max-width: 600px;
    margin: 0px 40px 0px 40px;
  }
  .certify_head {
    font-size: 25px;
  }
  .cert_desc_text {
    font-size: 25px;
  }
  .ic_on {
    height: 30px;
    width: 30px;
  }
  .li_nk {
    width: 50%;
    padding: 20px 25px;
  }
  .te_xt {
    font-size: 24px;
    line-height: normal;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1250px) {
  .ProfileCard {
    margin-top: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  .switch_P {
    flex-direction: column;
  }
  .Image {
    height: 350px;
    width: 350px;
  }
  .switchImage,
  .activeImage {
    height: 70px;
    width: 70px;
  }
  .desc_glass {
    min-width: 1000px;
    padding: 30px 40px;
  }
  .name {
    font-size: 38px;
  }
  .desg {
    font-size: 23px;
  }
  .P_loc {
    font-size: 22px;
  }
  .P_Card {
    min-width: 500px;
  }
  .P_summary {
    font-size: 24px;
  }
  .P_skill_summary {
    font-size: 24px;
  }
  .Desc_Glass1 {
    max-width: 600px;
    margin: 0px 40px 0px 40px;
  }
  .certify_head {
    font-size: 25px;
  }
  .cert_desc_text {
    font-size: 25px;
  }
  .ic_on {
    height: 40px;
    width: 40px;
  }
  .li_nk {
    width: 50%;
    padding: 20px 25px;
  }
  .te_xt {
    font-size: 28px;
    line-height: normal;
  }
}
