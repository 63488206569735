.carousel-pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  overflow-x: hidden;
  width: 100%;
}

.car-image-wrapper {
  display: flex;
  align-items: center;
  gap: 40px; /* Space between images */
  white-space: nowrap;
  width: max-content;
}

.lang-image {
  display: flex;
  align-items: center;
}

.lang-image img {
  width: auto; /* Maintain aspect ratio */
  opacity: 60%;
  transition: opacity 0.3s ease-in-out;
}

.lang-image img:hover {
  opacity: 100%;
}

/* Responsive Adjustments */
@media only screen and (max-width: 768px) {
  .lang-image img {
      height: 100px;
  }
}
