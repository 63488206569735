@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

.Hero {
  width: 100vw;
  height: 100dvh;
  margin-top: 0;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.location_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  /* animation: fadeIn 1s ease-out; */
}

.tint-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.tint_overlay_mobile {
  display: none;
}
.type-main {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 3;
}

.motto_text {
  position: absolute;
  top: 17%;
  padding: 0px 20px 0px 20px;
  left: 3%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50px;
  gap: 15px;
}
.green-text {
  color: #dbff00;
}
.motto_text h3 {
  z-index: 1;
  font-family: "Urbanist", sans-serif;
  text-align: center;
  font-size: 45px;
  font-weight: 650;
  color: #ffffff;
  transition: opacity 0.5s;
  text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.765);
}
.motto_text h5 {
  z-index: 1;
  font-family: "Urbanist", sans-serif;
  text-align: center;
  font-size: 22px;
  font-weight: 350;
  color: #ffffff;
  transition: opacity 0.5s;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.751);
}

.section_dots {
  position: absolute;
  bottom: 9%;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  gap: 5px;
}
.dotnoactive {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  height: 20px;
  width: 20px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
}
.dotactive {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  height: 25px;
  width: 25px;
  color: #dbff00;
  cursor: pointer;
  border-radius: 50%;
}

.location_btn {
  z-index: 1;
  position: absolute;
  bottom: 8%;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px 6px 8px;
  color: #ffffff;
  border-radius: 10px;
  gap: 8px;
  cursor: pointer;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 5s linear;
  animation: fadeIn 1s ease-out;
}
.location_mobile_btn {
  display: none;
}
.loc_btn {
  height: 30px;
  width: 30px;
}

.loc_btnactive {
  height: 30px;
  width: 30px;
  color: #dbff00;
}
.location_name {
  font-family: Barlow;
  font-size: 25px;
  font-weight: 500;
  color: #ffffff;
  text-shadow: rgba(0, 0, 0, 1);
  text-align: center;
}
.loc_glassmorphism {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 380px;
  height: 180px;
  padding-right: 15px;
  left: 3.4%;
  bottom: 4.7%;
  border-radius: 15px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(10px);
  border: 1.1px solid #dbff00;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  color: black;
  transition: opacity 5s linear;
  animation: slideIn 0.8s ease-out;
  overflow: hidden;
}
.backgroundTint {
  display: none;
}
.loc_glassmorphism::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300%;
  height: 20%;
  opacity: 20%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 40%,
    rgba(255, 255, 255, 0.3) 65%,
    rgba(255, 255, 255, 0.5) 100%,
    rgba(255, 255, 255, 0.3) 65%,
    rgba(255, 255, 255, 0.1) 40%
  );
  transform: rotate(45deg);
  animation: brightening 3.5s ease-in infinite;
}

.loc_glassmorphism_mobile {
  display: none;
  /* animation: fadeIn 1s ease-out; */
}
.loc_glassmorphism_mobile::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300%;
  height: 20%;
  opacity: 10%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 40%,
    rgba(255, 255, 255, 0.3) 65%,
    rgba(255, 255, 255, 0.5) 100%,
    rgba(255, 255, 255, 0.3) 65%,
    rgba(255, 255, 255, 0.1) 40%
  );
  transform: rotate(45deg);
  animation: brightening 3.5s ease-in infinite;
}
@keyframes brightening {
  0% {
    transform: translate(-100%, 100%) rotate(45deg);
  }
  100% {
    transform: translate(100%, -100%) rotate(45deg);
  }
}
.card_body {
  display: flex;
  margin-left: 60px;
  margin-right: 10px;
  flex-direction: column;
}
.location-name {
  font-family: Barlow;
  font-size: 35px;
  font-weight: 550;
  color: #ffffff;
  text-shadow: rgba(0, 0, 0, 1);
  text-align: left;
  animation: slideIn 2s ease-out;
}

.location-state-name {
  font-family: Barlow;
  font-size: 22px;
  font-weight: 450;
  color: #ffffff;
  text-shadow: rgba(0, 0, 0, 1);
  text-align: left;
}

.hero-location {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
  animation: slideIn 3s ease-out;
}

.scroll-animation {
  position: absolute;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 25px;
  width: 25px;
  bottom: 30px;
  cursor: pointer;
}

@keyframes scrollEffect {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  25% {
    opacity: 0.5;
    transform: translateY(-15px);
  }
  75% {
    opacity: 0.9;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.scroll-animation img {
  animation: scrollEffect 3s infinite;
  height: 80%;
  width: 80%;
  object-fit: fill;
}

@media screen and (min-width: 100px) and (max-width: 321px) {
  .location_image {
    height: 55%;
  }
  .tint-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .tint_overlay_mobile {
    position: absolute;
    display: flex;
    top: 39%;
    left: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 2;
  }
  .motto_text {
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    margin: 0;
    right: 0;
    top: 13%;
    z-index: 3;
    gap: 200px;
  }
  .motto_text h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
  }
  .motto_text h5 {
    font-size: 17px;
    font-weight: 400;
  }
  .loc_mobile_btnblock {
    height: 20px;
    width: 20px;
    color: #a6a6a681;
  }
  .loc_mobile_btn {
    height: 20px;
    width: 20px;
  }

  .location_mobile_btn {
    left: 6%;
    bottom: 7.9%;
    border: 2px solid rgba(255, 255, 255, 0.144);
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: #ffffff;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 3;
  }
  .location_btn {
    display: none;
  }
  .card_body {
    margin-left: 28px;
  }
  .loc_glassmorphism {
    display: none;
  }
  .loc_glassmorphism_mobile {
    position: absolute;
    display: flex;
    padding-right: 11px;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 156px;
    left: 0%;
    right: 0%;
    bottom: 12%;
    border-radius: 15px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(
      0deg,
      rgba(31, 42, 64, 0.468) 0%,
      rgba(23, 0, 42, 0.5) 100%
    );
    box-shadow: 0 7px 20px 5px #00000088;
    margin: 20px;
    color: black;
    overflow: hidden;
    z-index: 3;
  }
  .backgroundTint {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 170px;
    left: 0%;
    right: 0%;
    bottom: 12%;
    border-radius: 15px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(136, 18, 151, 1) 29%,
      rgba(152, 22, 120, 1) 82%,
      rgba(0, 0, 0, 1) 100%
    );
    filter: blur(140px);
    scale: 1.2;
    opacity: 60%;
    box-shadow: 0 7px 20px 5px #00000088;
    margin: 20px;
    color: black;
    overflow: hidden;
    z-index: 2;
  }
  .location_name {
    font-size: 18px;
    font-weight: 300;
  }
  .location-name {
    font-size: 24px;
    font-weight: bold;
    animation: fadeIn 1s ease-out;
  }

  .location-state-name {
    font-size: 20px;
    font-weight: 300;
  }

  .hero-location {
    font-size: 12px;
    animation: fadeIn 1s ease-out;
  }
  .scroll-animation {
    height: 20px;
    width: 20px;
    bottom: 6px;
  }
  .section_dots {
    bottom: 8.5%;
    z-index: 3;
  }
  .dotnoactive {
    height: 15px;
    width: 15px;
  }
  .dotactive {
    height: 20px;
    width: 20px;
  }
}

@media screen and (min-width: 321px) and (max-width: 376px) {
  .Hero img {
    height: 55%;
  }
  .tint-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .tint_overlay_mobile {
    position: absolute;
    display: flex;
    top: 39%;
    left: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 2;
  }
  .motto_text {
    width: 100%;
    align-items: center;
    justify-content: center;
    left: 0;
    margin: 0;
    right: 0;
    top: 14%;
    z-index: 3;
    gap: 160px;
  }
  .motto_text h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }
  .motto_text h5 {
    font-size: 19px;
    font-weight: 400;
  }
  .loc_mobile_btnblock {
    height: 20px;
    width: 20px;
    color: #a6a6a681;
  }
  .loc_mobile_btn {
    height: 20px;
    width: 20px;
  }
  .location_mobile_btn {
    left: 5%;
    bottom: 7.9%;
    border: 2px solid rgba(255, 255, 255, 0.144);
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: #ffffff;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 3;
  }
  .location_btn {
    display: none;
  }

  .card_body {
    margin-left: 28px;
  }
  .loc_glassmorphism {
    display: none;
  }
  .loc_glassmorphism_mobile {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 12px;
    width: 280px;
    height: 156px;
    left: 8%;
    bottom: 12%;
    border-radius: 15px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(
      0deg,
      rgba(31, 42, 64, 0.468) 0%,
      rgba(23, 0, 42, 0.5) 100%
    );
    box-shadow: 0 7px 20px 5px #00000088;
    margin: 20px;
    color: black;
    overflow: hidden;
    z-index: 3;
  }
  .backgroundTint {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 170px;
    left: 8%;
    bottom: 12%;
    border-radius: 15px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(136, 18, 151, 1) 29%,
      rgba(152, 22, 120, 1) 82%,
      rgba(0, 0, 0, 1) 100%
    );
    filter: blur(140px);
    scale: 1;
    opacity: 60%;
    box-shadow: 0 7px 20px 5px #00000088;
    margin: 20px;
    color: black;
    overflow: hidden;
    z-index: 2;
  }
  .location_name {
    font-size: 18px;
    font-weight: 300;
  }
  .location-name {
    font-size: 24px;
    font-weight: bold;
    animation: fadeIn 1s ease-out;
  }

  .location-state-name {
    font-size: 20px;
    font-weight: 300;
  }

  .hero-location {
    font-size: 12px;
    animation: fadeIn 1s ease-out;
  }
  .scroll-animation {
    height: 20px;
    width: 20px;
    bottom: 6px;
  }
  .section_dots {
    bottom: 8.5%;
    z-index: 3;
  }
  .dotnoactive {
    height: 15px;
    width: 15px;
  }
  .dotactive {
    height: 20px;
    width: 20px;
  }
}
@media screen and (min-width: 377px) and (max-width: 426px) {
  .type-main {
    display: flex;
  }
  .Hero img {
    height: 55%;
  }
  .tint-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .tint_overlay_mobile {
    position: absolute;
    display: flex;
    top: 40%;
    left: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 2;
  }
  .motto_text {
    width: 100%;
    align-items: center;
    justify-content: center;
    left: 0;
    margin: 0;
    right: 0;
    top: 14%;
    z-index: 3;
    gap: 180px;
  }
  .motto_text h3 {
    font-size: 23px;
    font-weight: 650;
    line-height: 30px;
    text-align: center;
  }
  .motto_text h5 {
    font-size: 20px;
    font-weight: 400;
  }
  .loc_mobile_btn {
    height: 20px;
    width: 20px;
  }
  .loc_mobile_btnblock {
    height: 20px;
    width: 20px;
    color: #a6a6a681;
  }
  .location_mobile_btn {
    left: 5%;
    bottom: 7.9%;
    border: 2px solid rgba(255, 255, 255, 0.144);
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: #ffffff;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.03);
    z-index: 3;
  }

  .location_btn {
    display: none;
  }
  .card_body {
    margin-left: 28px;
  }
  .loc_glassmorphism_mobile {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    width: 300px;
    height: 170px;
    left: 9%;
    bottom: 12.5%;
    border-radius: 15px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(
      0deg,
      rgba(31, 42, 64, 0.468) 0%,
      rgba(23, 0, 42, 0.5) 100%
    );
    box-shadow: 0 7px 10px 5px #00000088;
    margin: 20px;
    color: black;
    overflow: hidden;
    z-index: 3;
  }

  .backgroundTint {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 170px;
    left: 8%;
    bottom: 14.5%;
    border-radius: 15px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(136, 18, 151, 1) 29%,
      rgba(152, 22, 120, 1) 82%,
      rgba(0, 0, 0, 1) 100%
    );
    filter: blur(140px);
    scale: 1.2;
    opacity: 60%;
    box-shadow: 0 7px 20px 5px #00000088;
    margin: 20px;
    color: black;
    overflow: hidden;
    z-index: 2;
  }
  .loc_glassmorphism {
    display: none;
  }
  .location_name {
    font-size: 18px;
    font-weight: 300;
  }
  .location-name {
    font-size: 24px;
    font-weight: bold;
    animation: fadeIn 1s ease-out;
  }

  .location-state-name {
    font-size: 20px;
    font-weight: 300;
  }

  .hero-location {
    font-size: 12px;
    animation: fadeIn 1s ease-out;
  }
  .scroll-animation {
    height: 20px;
    width: 20px;
    bottom: 6px;
  }
  .section_dots {
    bottom: 8.2%;
    right: 6.5%;
    z-index: 3;
  }
  .dotnoactive {
    height: 18px;
    width: 18px;
  }
  .dotactive {
    height: 23px;
    width: 23px;
  }
}

@media screen and (min-width: 427px) and (max-width: 767px) {
  .Hero img {
    height: 70%;
  }
  .tint-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  .backgroundTint {
    display: none;
  }
  .tint_overlay_mobile {
    position: absolute;
    display: flex;
    top: 56%;
    left: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 2;
  }
  .motto_text {
    width: 90%;
    align-items: center;
    justify-content: center;
    left: 4.5%;
    margin: 0;
    top: 15%;
    z-index: 3;
    gap: 300px;
  }

  .motto_text h3 {
    font-size: 25px;
    text-align: center;
    font-weight: 500;
  }
  .motto_text h5 {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
  .card_body {
    margin-left: 20px;
  }

  .loc_glassmorphism {
    bottom: 5%;
    left: 4.5%;
    width: 280px;
    height: 156px;
    overflow: hidden;
    padding-left: 30px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.062);
    z-index: 3;
  }
  .location_name {
    font-size: 18px;
    font-weight: 300;
  }
  .location-name {
    font-size: 24px;
    font-weight: bold;
  }

  .location-state-name {
    font-size: 20px;
    font-weight: 300;
  }

  .hero-location {
    font-size: 12px;
  }
  .loc_btn {
    height: 20px;
    width: 20px;
  }
  .location_btn {
    left: 7.5%;
    bottom: 8.5%;
    border: 1.2px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    z-index: 4;
  }
  .loc_btnactive {
    height: 20px;
    width: 20px;
  }
  .scroll-animation {
    height: 20px;
    width: 20px;
    bottom: 6px;
  }
  .dotnoactive {
    height: 15px;
    width: 15px;
  }
  .dotactive {
    height: 20px;
    width: 20px;
  }
}
