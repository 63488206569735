.mainContainer {
  color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin-inline: auto;
  padding: 51px 0px;
  position: relative;
  height: 100%;
}

.courseImgContainer > img {
  width: 100%;
  height: 55vh;
  object-fit: cover;
}

.courseHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid #ffffff1e;

  position: -webkit-sticky;
  position: sticky !important;
  top: 45px !important;
  z-index: 12;
  background: radial-gradient(ellipse at bottom, #18222f 0%, #090a0f 100%);
}

.courseTitle {
  font-size: 40px;
  font-family: barlow;
  font-weight: 400;
}

.courseApply {
  background-color: #ddff00c0;
  color: #000;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 20px;
  line-height: normal;
  font-family: barlow;
  font-weight: 400;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.courseApply:hover {
  position: relative;
  top: 2px;
  left: 2px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: all 0.2s;
}
.NavDescContainer {
  display: flex;
  justify-content: flex-start;
  gap: 5%;
  padding: 30px 0;
}

.course-nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 2px;
  height: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  z-index: 10;
}
.course-nav,
.course-nav-item {
  width: 200px;
}
.course-nav-item {
  font-size: 18px;
  font-family: barlow;
  font-weight: 400;
  cursor: pointer;
  opacity: 50%;
  /* padding-left: 30px; */
}
.course-nav-item:hover {
  opacity: 1;
}

.active {
  color: #ddff00;
}

.courseDescription {
  /* width: 100%; */
}
.courseDescHeading {
  font-size: 28px;
  font-family: barlow;
  font-weight: 200;
  color: #ffffff;
  margin-bottom: 20px;
}

.cardStyle :hover {
  background-color: #313439;
  border-radius: 20px;
}

.bodyCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-family: barlow;
  font-weight: 200;
  color: #ffffff;
  margin-bottom: 20px;
}

.right-arrow {
  display: flex;
  height: 20px;
  width: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cross-icon {
  display: flex;
  height: 35px;
  width: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
  margin-top: 4px;
}
.courseDesc {
  font-size: 18px;
  font-family: barlow;
  font-weight: 200;
  margin-bottom: 60px;
  padding-right: 20px;
  opacity: 75%;
}

.accordion-item {
  background-color: transparent !important;
  color: #fff !important;
}

.accordion-button {
  background-color: #646464 !important;
}

.accordion-button::after {
  background-image: url("https://ik.imagekit.io/z2g6kc7ex/Squbix/Courses/DownArrow.png?updatedAt=1710824900088") !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("https://ik.imagekit.io/z2g6kc7ex/Squbix/Courses/DownArrow.png?updatedAt=1710824900088") !important;
}
.accordion-button:not(.collapsed) {
  color: white !important;
}

.collapsed {
  background-color: transparent !important;
  color: #fff !important;
}

.content-body {
  margin-top: 10px;
  margin-bottom: 20px;
}

.content-heading {
  font-size: 24px;
  font-family: barlow;
  font-weight: 200;
  color: #ffffff;
}

.content-item {
  font-size: 18px;
  font-family: barlow;
  font-weight: 200;
  opacity: 75%;
  margin-left: 15px;
}

.squbix-certificate {
  text-align: center;
}

.squbix-certificate img {
  width: 50%;
  border-radius: 8px;
}

@media screen and (max-width: 770px) {
  .courseImgContainer > img {
    height: 40vh;
  }

  .courseHeading {
    padding: 10px 20px;
    flex-wrap: wrap;
  }
  .course-nav {
    display: none;
  }
  .courseDescription {
    padding: 0 20px;
  }
  .courseDesc {
    padding: 0;
  }

  .squbix-certificate img {
    width: 80%;
  }
}
