.EmployeePage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.EmpCard {
  display: flex;
  justify-content: space-around;
  width: 800px;
  color: #fff;
  background: rgba(122, 122, 122, 0.07);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);
}

.empImage {
  margin-top: 20px;
}

.EmpName {
  font-family: "barlow", sans-serif;
  font-weight: 600;
  font-size: 30px;
  padding-top: 20px;
}

.EmpRole {
  font-family: "barlow", sans-serif;
  font-style: italic;
  padding-bottom: 30px;
}

.EmpMail {
  padding-bottom: 10px;
}

.EmpMail,
.EmpMob,
.Stats {
  font-family: "barlow", sans-serif;
}

.Stats {
  display: flex;
  justify-content: space-between;
}

.empID,
.EmpStat {
  font-weight: 800;
  font-size: 20px;
}

.EmpCard a {
  text-decoration: none !important;
  color: inherit !important;
}

.EmpCard a:hover {
  color: #14213d !important;
}
