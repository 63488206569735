body{
  background: #090a0f;
  /* overflow-x: hidden; */
}

/* * {
  cursor: url('https://cur.cursors-4u.net/cursors/cur-2/cur116.cur'), default;
}

button,a,link{
  cursor: url('https://cur.cursors-4u.net/user/use-1/use137.cur'), pointer;
} */

.App {
  font-family: barlow, sans-serif;
  margin: 0;
  justify-content: center;
  scroll-behavior: smooth;
  width: 100vw;
  background: black;
  background-size: cover;
  background-attachment: fixed; /* make the background fixed */
}
html {
  background: #090a0f;
  overflow-y:   scroll;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}


@font-face {
  font-family: TheropodsDemoBold;
  src: url(./Fonts/TheropodsDemoBold.ttf);
}
@font-face {
  font-family: TheropodsDemoItalic;
  src: url(./Fonts/TheropodsDemoItalic.ttf);
}
@font-face {
  font-family: TheropodsDemoRegular;
  src: url(./Fonts/TheropodsDemoRegular.ttf);
}