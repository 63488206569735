.StatLiner {
  color: #fff;
  position: relative;
  z-index: 1;
}

.dash-bg1 {
  display: flex;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 10%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 19, 246, 1) 25%,
    rgba(44, 26, 255, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: blur(140px);
  z-index: -1;
  scale: 1.1;
  rotate: 180deg;
  opacity: 20%;
}

.StatsContainer {
  position: relative;
  scale: 0.9;
  top: 24px;
  display: flex !important;
  gap: 30px;
  justify-content: center !important;
  height: 100vh;
}

.swiper-wrapper {
  width: 100%;
}

.stat1 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border-radius: 20px;
  border: 1px solid black;
  /* box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15); */
}

.Statimg {
  height: 100%;
  width: 100%;
  border: 1px solid black;
  /* border-radius: 20px; */
  overflow: hidden;
  object-fit: cover;
}

.Statimg {
  border-radius: 20px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}

.ImgTitle {
  position: absolute;
  top: 80%;
  color: #fff;
  font-family: "barlow";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* padding-bottom: 13px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.ReadMoreLink {
  color: #dbff00;
  font-family: "barlow";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 25.746px */
  text-decoration-line: underline;
}

.ImgDesc {
  position: absolute;
  top: 84%;
  color: #fff;
  font-family: "barlow";
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  padding-top: 10px;
  width: 95%;
  /* 27.591px */
}

.ImgDescription {
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 22px 30px;
  position: relative;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, transparent 40%);
}
.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 5;
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 2px;
  background: #fff !important;
  border-radius: 100%;
  opacity: 0.5;
  cursor: pointer;
}

.dot.active {
  opacity: 1;
  width: 8px;
  height: 8px;
}

.col1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: hidden;
}

.scrollBtns {
  display: flex;
  gap: 30px;
}
.jobbtn {
  height: 30px;
  cursor: pobarlow;
}
/* .jobbtn:hover{
  opacity: 1 !important;
} */

.jobslide-container {
  padding-left: 0;
  padding-right: 30px;
}
.jobslide-container:nth-child() .slick-current .jobslide-container {
  padding-left: 0;
}

.EachJob {
  --left: 0px;
  --top: 0px;
  position: relative;
  background-color: rgba(225, 225, 225, 0.1);
  backdrop-filter: blur(10px);
  padding: 14px;
  border: 0.25px solid #444444;
  border-radius: 20px;
  height: 20%;
  cursor: pobarlow;
  cursor: pointer;
}
.EachJob:hover::before {
  opacity: 1;
}

.EachJob::before,
.EachJob::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 500ms;
}

.EachJob::before {
  background: radial-gradient(
    800px circle at var(--left) var(--top),
    rgba(225, 225, 225, 0.2),
    transparent 20%
  );
  z-index: -1;
}

.EachJob::after {
  background: radial-gradient(
    800px circle at var(--left) var(--top),
    rgba(225, 225, 225, 0.4),
    transparent 50%
  );
  z-index: -1;
}

.EachJobHolder {
  /* background-color: rgb(23, 23, 23); */

  z-index: 1;
}
.jobhover:hover {
  opacity: 1 !important;
}
.JobTitle {
  font-family: "barlow";
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.JobLocContainer {
  display: flex;
  padding: 10px 0;
  font-size: 16px;
  align-items: center;
  opacity: 50%;
}
.JobLocContainer img {
  width: 30px;
  height: 30px;
}

.JobLister {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px;
}
.JobLoc {
  font-family: "barlow";
  font-weight: 300;
  line-height: 20px;
  text-align: left;
}

.JobSkillsContainer {
  border-top: 1px solid rgba(126, 126, 126, 0.2);
  border-bottom: 1px solid rgba(126, 126, 126, 0.2);
  padding: 15px 0;
  font-family: "barlow";
  font-size: 16px;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.JobSkill {
  font-family: "barlow";
  font-size: 15px;
  font-weight: 100;
  line-height: 13px;
  letter-spacing: 1px;
  text-align: left;
  opacity: 75%;
}

.JobSpecs {
  margin-top: 14px;
  display: flex;
  align-items: center;
}

.JobTime {
  background: #00000033;
  padding: 5px 10px;
  text-align: center;
  border-radius: 10px;
}
.JobTime > span {
  opacity: 50%;
  font-family: "barlow";
  font-weight: 300;
}
.applybtn {
  margin: 5px;
  padding: 10px;
  height: 40px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.applybtn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.GolStat1 {
  /* padding-top: 25px; */
  color: #000;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  scale: 0.8;
}

.col2 {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0;
  gap: 30px;
}

.NoticeStat {
  width: 276px;
  height: 58px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #aeb2b3;
  opacity: 0.9;
  color: #000;
  font-family: barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  display: flex;
  align-items: center;
}

.mySwiper {
  position: relative;
  width: 282px;
  height: 100%;
}

.EachSlide {
  padding: 3% 5%;
  height: 100%;
  width: 100%;
  background-color: rgba(225, 225, 225, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 0.25px solid #444444;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}
.sizer {
  scale: 1;
  position: relative;
  border-radius: 15px;
}

.StatType {
  color: rgb(219, 255, 0);
  font-family: "barlow";
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-top: 6px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(126, 126, 126, 0.2);
  width: 100%;
}

.StatAuthor {
  color: rgba(255, 255, 255, 0.65);
  font-family: "barlow";
  font-size: 14.667px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  height: 20%;
  /* 22px */
}

.swiper-pagination-bullet {
  background-color: #fff !important;
}

/* Change the color of the active pagination dot */
.swiper-pagination-bullet-active {
  background-color: #dbff00 !important;
}

.StatDetails {
  padding-top: 10px;
  padding-bottom: 13px;
  font-family: "barlow";
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  /* height: 20%; */
}

.eventContainer {
  height: fit-content;
  width: 100%;
}
.stat2 {
  /* position: relative;
  top: 40px; */
}

.JobSectionHeading {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #f1f1f1;
  font-family: "barlow";
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: 31.726px;
  /* 113.308% */
  letter-spacing: -0.227px;
  margin: 0;
  /* opacity: 75%; */
}

.webinar-black {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, transparent 25%);
  z-index: 1;
}

.StatCardImg {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border-radius: 15px;
}
.StatCardImg img {
  width: 100%;
  scale: 1.1;
}

.single {
  display: flex;
  justify-content: center;
}

.single img {
  width: 60%;
}

.StatLink {
  opacity: 85%;
  position: relative;
  z-index: 2;
}
.StatLink img {
  width: 20%;
}
.StatLink:hover {
  opacity: 100%;
}

.mobile-heading {
  display: none;
}
/* DON'T UNCOMMENT CAN CAUSE BREAKING CHANGES */

/* .Slide0 {
  background-image: url("../Assets/Images/Stats/StatCard1.png");
}

.Slide1 {
  background-image: url("../Assets/Images/Stats/StatCard2.png");
}

.Slide2 {
  background-image: url("../Assets/Images/Stats/StatCard1.png");
} */

@media only screen and (max-width: 770px) {
  .StatLiner {
    display: block;
    height: 100%;
  }
  .StatsContainer {
    display: block;
    flex-direction: column;
    height: 100%;
    top: 0;
    scale: 1;
    gap: 0;
  }
  .stat1 {
    height: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  .col1 {
    width: 100%;
    overflow-x: hidden;
    gap: 0;
    height: 100%;
  }

  .Statimg {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
  }

  .ImgTitle {
    font-family: barlow;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    top: auto;
    bottom: 15%;
    text-align: left;
    align-items: flex-start;
  }

  .ImgDescription {
    position: absolute;
    text-overflow: ellipsis;
  }

  .ImgDesc {
    top: 82%;
    font-size: 12px;
    display: none;
  }

  .GolStatus {
    display: none;
  }

  .col2 {
    display: block;
  }

  .ReadMoreLink {
    font-family: barlow;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }

  .JobSectionHeading {
    font-size: 24px;
    opacity: 75%;
  }

  .scrollBtns {
    display: none;
  }

  .JobLister {
    padding-top: 50px;
    padding-left: 25px;
    padding-bottom: 20px;
  }

  .pastEventsSwiper {
    width: 90%;
    height: 40vh !important;
  }

  .stat2 {
    position: relative;
  }
  .EachSlide {
    height: 100%;
    padding-top: 5%;
  }
  .swiper-wrapper {
    width: 100%;
  }

  .mySwiper {
    width: 90%;
    height: 230px;
  }

  .jobslide-container {
    padding-left: 25px !important;
  }

  .eventContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .StatType {
    text-align: left;
  }

  .eventDetailContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 60%;
  }

  .StatDetails {
    font-family: barlow;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .StatAuthor {
    font-family: barlow;
    font-size: 14px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
  }
  .StatCardImg {
    position: static;
  }
  .StatCardImg img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    scale: 1;
  }

  .single img {
    width: 30%;
  }

  .mobile-heading {
    display: block;
    font-family: "barlow";
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    margin: 50px 0 20px;
    padding-inline: 25px;
    opacity: 75%;
  }
}

@media only screen and (min-width: 771px) and (max-width: 1440px) {
  .stat1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  .col1 {
    width: 100%;
    overflow-x: hidden;
  }

  .Statimg {
    /* border-radius: 20px; */
    height: 100%;
    object-fit: cover;
  }
  .ImgTitle {
    font-size: 16px;
    top: 80%;
  }
  .ImgDescription {
    position: absolute;
    bottom: 0;
    height: 100%;
    border-radius: 0px 0px 20px 20px;
  }

  .ImgDesc {
    font-size: 15px;
  }

  .GolStat1 {
    display: flex;
    justify-content: space-between;
    scale: 1;
    position: relative;
    top: 0;
  }

  .col2 {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0;
    justify-content: space-between;
  }

  .ReadMoreLink {
    font-size: 15px;
    font-style: normal;
  }

  .scrollBtns {
    scale: 0.9;
    position: relative;
    right: 10px;
  }

  .pastEventsSwiper {
    width: 100% !important;
    height: 100% !important;
  }

  .stat2 {
    position: relative;
    /* top: -10px; */
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1920px) {
  .StatsContainer {
    width: 1440px;
    margin: auto;
  }
  .stat1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  .col1 {
    width: 100%;
    overflow-x: hidden;
  }
  .mySwiper {
    height: 100%;
  }

  .ImgDescription {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 20px 20px;
  }

  .GolStat1 {
    display: flex;
    justify-content: space-between;
    scale: 1;
    position: relative;
    top: 0;
  }

  .col2 {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0;
    justify-content: space-between;
  }

  .ImgDesc {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    /* 27.591px */
  }

  .ReadMoreLink {
    font-size: 15px;
    font-style: normal;
  }

  .scrollBtns {
    scale: 0.9;
    position: relative;
    right: 10px;
  }

  .pastEventsSwiper {
    width: 100%;
    height: 100%;
  }

  .stat2 {
    position: relative;
    /* top: -10px; */
  }
  .mySwiper {
    height: 100%;
  }
}

@media only screen and (min-width: 1921px) {
  .StatsContainer {
    width: 1440px;
    margin: auto;
  }
  .stat1 {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }

  .mySwiper {
    height: 100%;
  }
  .col1 {
    width: 100%;
    overflow-x: hidden;
  }

  .ImgDescription {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 20px 20px;
  }

  .GolStat1 {
    scale: 1;
  }

  .col2 {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0;
  }

  .StatCardImg img {
    height: auto;
  }

  .pastEventsSwiper {
    width: 100% !important;
    height: 100% !important;
  }
  .StatType {
    padding-bottom: 20px;
  }

  .StatDetails {
    padding-top: 20px;
    font-size: 28px;
    margin-bottom: 20px;
  }
}
