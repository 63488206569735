.Navbar {
  position: fixed;
  /* margin-top: 38px; */
  top: 0;
  z-index: 999;
}

.Navbar-d {
  display: flex;
  justify-content: space-between;
  /* background: rgba(255, 255, 255, 0.1); */
  border-top: 0.2px solid rgba(126, 126, 126, 0.2);
  border-bottom: 0.2px solid rgba(126, 126, 126, 0.2);
  border-right: 1px solid rgba(126, 126, 126, 0.3);
  align-items: center;
  height: 51px;
  padding: 0;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(73.84552001953125px);
  font-family: "barlow", sans-serif;
  width: 100vw;
}

.Navbar-l {
  color: white;
  /* font-size: 20px; */
  /* font-weight: bolder; */
  margin-left: 15px;
  line-height: normal;
  padding-right: 40px;
}

.Navbar-l img :hover {
  cursor: pointer;
  filter: grayscale();
}

.Navbar-l-item {
  display: none;
}

.Navbar-r {
  display: flex;
  align-items: center;
  color: white;
  height: 100%;
}

.Navbar-r div {
  text-wrap: nowrap;
  font-size: 15px;
  height: 100%;
  border-left: 1px solid rgba(126, 126, 126, 0.3);
}

.Navbar-r-item {
  width: 135px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  opacity: 40%;
}

.Navbar-r-item:hover {
  background: rgba(255, 255, 255, 0.1);
  opacity: 100%;
  transition: opacity 0.3s linear;
}

.Navbar-r-item-active {
  width: 135px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.25);
}

.Navbar-r-item a:hover,
.Navbar-r-item a:active {
  /* margin-left:15px; */
  color: #dbff00;
}

/* .nav-text{
  display: inline-block;
} */

@media screen and (max-width: 770px) {
  .Navbar {
    position: fixed;
    overflow: hidden;
    top: 0;
    margin: 0;
    height: 100%;
    width: 100vw;
  }

  .remove-bg {
    height: 51px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(73.84552001953125px);
  }

  .Navbar-d {
    height: auto;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: inherit;
    width: inherit;
    border-radius: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
  }

  .Navbar-l {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 10px 10px;
  }

  .Navbar-l-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Navbar-r {
    height: 83%;
    width: 100%;
    display: flex;
    gap: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 13%;
  }

  .Navbar-r hr {
    border: 1px solid white;
    width: 100%;
  }

  .Navbar-r div {
    user-select: none;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  .Navbar-r div:hover {
    background: rgba(219, 255, 0, 0.15);
  }

  .Navbar-r-item {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 2.5rem;
  }

  .Navbar-r-item-active {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 2.5rem;
  }

  .Navbar-d .Navbar-r.show-nav-link {
    height: 100%;
    display: flex;
  }

  .Navbar-d .Navbar-r.hide-nav-link {
    display: none;
  }

  .squbix-logo {
    display: none;
  }

  .add-bg {
    /* background-color: rgb(190, 32, 32); */
    background: #000;
    background-size: cover;
    height: 100dvh;
  }

  .remove-bg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 770px) and (max-width: 890px) {
  .Navbar-r-item,
  .Navbar-r-item-active {
    width: 110px;
  }
}
